// utils/fileNameMapping.ts
export const fileNameMappings: { [category: string]: { [glyph_map: string]: {[letter: string]: string } } } = {

  "latin_signs": {
    "description": {"en": "Latin Symbols"},
    "glyph_map": {
      "!": "exclam",
      "\"": "quotedbl",
      "#": "numbersign",
      "$": "dollar",
      "%": "percent",
      "&": "ampersand",
      "'": "quotesingle",
      "(": "parenleft",
      ")": "parenright",
      "*": "asterisk",
      "+": "plus",
      ",": "comma",
      "-": "hyphen",
      ".": "period",
      "/": "slash",
      ":": "colon",
      ";": "semicolon",
      "<": "less",
      "=": "equal",
      ">": "greater",
      "?": "question",
      "@": "at",
      "[": "bracketleft",
      "\\": "backslash",
      "]": "bracketright",
      "^": "asciicircum",
      "_": "underscore",
      "{": "braceleft",
      "|": "bar",
      "}": "braceright",
      "~": "asciitilde",
      "¡": "exclamdown",
      "¢": "cent",
      "£": "sterling",
      "¤": "currency",
      "¥": "yen",
      "¦": "brokenbar",
      "§": "section",
      "¨": "dieresis",
      "©": "copyright",
      "ª": "ordfeminine",
      "«": "guillemotleft",
      "¬": "logicalnot",
      "­": "uni00AD",
      "®": "registered",
      "¯": "macron",
      "°": "degree",
      "±": "plusminus",
      "²": "uni00B2",
      "³": "uni00B3",
      "´": "acute",
      "µ": "mu",
      "¶": "paragraph",
      "·": "periodcentered",
      "¸": "cedilla",
      "¹": "uni00B9",
      "º": "ordmasculine",
      "»": "guillemotright",
      "¼": "onequarter",
      "½": "onehalf",
      "¾": "threequarters",
      "¿": "questiondown",
      "ˆ": "circumflex",
      "˚": "ring",
      "˜": "tilde",
      "–": "endash",
      "—": "emdash",
      "‘": "quoteleft",
      "’": "quoteright",
      "‚": "quotesinglbase",
      "“": "quotedblleft",
      "”": "quotedblright",
      "„": "quotedblbase",
      "•": "bullet",
      "…": "ellipsis",
      "₹": "rupee"
    }
  },

  "latin_characters": {
    "description": {"en": "Latin Basic"},
    "glyph_map": {
      "A": "A",
      "B": "B",
      "C": "C",
      "D": "D",
      "E": "E",
      "F": "F",
      "G": "G",
      "H": "H",
      "I": "I",
      "J": "J",
      "K": "K",
      "L": "L",
      "M": "M",
      "N": "N",
      "O": "O",
      "P": "P",
      "Q": "Q",
      "R": "R",
      "S": "S",
      "T": "T",
      "U": "U",
      "V": "V",
      "W": "W",
      "X": "X",
      "Y": "Y",
      "Z": "Z",
      "a": "a",
      "b": "b",
      "c": "c",
      "d": "d",
      "e": "e",
      "f": "f",
      "g": "g",
      "h": "h",
      "i": "i",
      "j": "j",
      "k": "k",
      "l": "l",
      "m": "m",
      "n": "n",
      "o": "o",
      "p": "p",
      "q": "q",
      "r": "r",
      "s": "s",
      "t": "t",
      "u": "u",
      "v": "v",
      "w": "w",
      "x": "x",
      "y": "y",
      "z": "z"
    }
  },

  "latin_extended": {
    "description": {"en": "Latin Extended"},
    "glyph_map": {
      "À": "Agrave",
      "Á": "Aacute",
      "Â": "Acircumflex",
      "Ã": "Atilde",
      "Ä": "Adieresis",
      "Å": "Aring",
      "Æ": "AE",
      "Ç": "Ccedilla",
      "È": "Egrave",
      "É": "Eacute",
      "Ê": "Ecircumflex",
      "Ë": "Edieresis",
      "Ì": "Igrave",
      "Í": "Iacute",
      "Î": "Icircumflex",
      "Ï": "Idieresis",
      "Ð": "Eth",
      "Ñ": "Ntilde",
      "Ò": "Ograve",
      "Ó": "Oacute",
      "Ô": "Ocircumflex",
      "Õ": "Otilde",
      "Ö": "Odieresis",
      "×": "multiply",
      "Ø": "Oslash",
      "Ù": "Ugrave",
      "Ú": "Uacute",
      "Û": "Ucircumflex",
      "Ü": "Udieresis",
      "Ý": "Yacute",
      "Þ": "Thorn",
      "ß": "germandbls",
      "à": "agrave",
      "á": "aacute",
      "â": "acircumflex",
      "ã": "atilde",
      "ä": "adieresis",
      "å": "aring",
      "æ": "ae",
      "ç": "ccedilla",
      "è": "egrave",
      "é": "eacute",
      "ê": "ecircumflex",
      "ë": "edieresis",
      "ì": "igrave",
      "í": "iacute",
      "î": "icircumflex",
      "ï": "idieresis",
      "ð": "eth",
      "ñ": "ntilde",
      "ò": "ograve",
      "ó": "oacute",
      "ô": "ocircumflex",
      "õ": "otilde",
      "ö": "odieresis",
      "÷": "divide",
      "ø": "oslash",
      "ù": "ugrave",
      "ú": "uacute",
      "û": "ucircumflex",
      "ü": "udieresis",
      "ý": "yacute",
      "þ": "thorn",
      "ÿ": "ydieresis",
      "ı": "dotlessi",
      "Œ": "OE",
      "œ": "oe"
    }
  },

  "arabic_numerals": {
    "description": {"en": "Arabic Numerals"},
    "glyph_map": {
      "0": "zero",
      "1": "one",
      "2": "two",
      "3": "three",
      "4": "four",
      "5": "five",
      "6": "six",
      "7": "seven",
      "8": "eight",
      "9": "nine"
    }
  },

  "malayalam_swarams": {
    "description": {"en": "Malayalam Vowels"},
    "glyph_map": {
      "അ": "ml_a",
      "ആ": "ml_aa",
      "ഇ": "ml_i",
      "ഈ": "ml_ii",
      "ഉ": "ml_u",
      "ഊ": "ml_uu",
      "ഋ": "ml_r",
      "ഌ": "ml_l",
      "എ": "ml_e",
      "ഏ": "ml_ee",
      "ഐ": "ml_ai",
      "ഒ": "ml_o",
      "ഓ": "ml_oo",
      "ഔ": "ml_au",
      "ൠ": "ml_rr",
      "ൡ": "ml_ll",
      "ൟ": "archaic_ii"
    }
  },

  "malayalam_vyanjanam": {
    "description": {"en": "Malayalam Consonants"},
    "glyph_map": {
      "ക": "k1",
      "ഖ": "k2",
      "ഗ": "k3",
      "ഘ": "k4",
      "ങ": "ng",
      "ച": "ch1",
      "ഛ": "ch2",
      "ജ": "ch3",
      "ഝ": "ch4",
      "ഞ": "nj",
      "ട": "t1",
      "ഠ": "t2",
      "ഡ": "t3",
      "ഢ": "t4",
      "ണ": "nh",
      "ത": "th1",
      "ഥ": "th2",
      "ദ": "th3",
      "ധ": "th4",
      "ന": "n1",
      "ഩ": "n2",
      "പ": "p1",
      "ഫ": "p2",
      "ബ": "p3",
      "ഭ": "p4",
      "മ": "m1",
      "യ": "y1",
      "ര": "r3",
      "ല": "l3",
      "വ": "v1",
      "ശ": "z1",
      "ഷ": "sh",
      "സ": "s1",
      "ഹ": "h1",
      "ള": "lh",
      "ഴ": "zh",
      "റ": "rh",
      "ഺ": "rh_half"
    }
  },

  "malayalam_chill": {
    "description": {"en": "Malayalam Chillus"},
    "glyph_map": {
      "ൺ": "nhcil2",
      "ൻ": "n1cil2",
      "ർ": "r3cil2",
      "ൽ": "l3cil2",
      "ൾ": "lhcil2",
      "ൿ": "k1cil2",
      "ൔ": "m1cil",
      "ൕ": "y1cil",
      "ൖ": "zhcil"
    }
  },

  "malayalam_signs": {
    "description": {"en": "Malayalam Symbols"},
    "glyph_map": {
      "ഀ": "anusvaraabove",
      "ഁ": "mlchandrabindu",
      "ം": "anusvara",
      "ഃ": "visarga",
      "ഄ": "vedicanusvara",
      "഻": "verticalbarvirama",
      "഼": "circularvirama",
      "ഽ": "avagraha",
      "ാ": "a2",
      "ി": "i1",
      "ീ": "i2",
      "ു": "u1",
      "ൂ": "u2",
      "ൃ": "r1",
      "ൄ": "r2",
      "െ": "e1",
      "േ": "e2",
      "ൈ": "ai1",
      "ൊ": "o1",
      "ോ": "o2",
      "ൌ": "au1",
      "ൗ": "au2",
      "ൎ": "dotreph",
      "ൢ": "l1",
      "ൣ": "l2",
      "്": "xx"
    }
  },

  "malayalam_numerals": {
    "description": {"en": "Malayalam Numerals"},
    "glyph_map": {
      "൦": "ml_0",
      "൧": "ml_1",
      "൨": "ml_2",
      "൩": "ml_3",
      "൪": "ml_4",
      "൫": "ml_5",
      "൬": "ml_6",
      "൭": "ml_7",
      "൮": "ml_8",
      "൯": "ml_9",
      "൰": "ml_10",
      "൱": "ml_100",
      "൲": "ml_1000",
      "൳": "ml_quarter",
      "൴": "ml_half",
      "൵": "ml_3quarter",
      "൏": "para",
      "൘": "arakaani",
      "൙": "aramaa",
      "൚": "muunnukaani",
      "൛": "orumaa",
      "൜": "rantumaa",
      "൝": "muunnumaa",
      "൞": "naalumaa",
      "൶": "maakaani",
      "൷": "arakkaal",
      "൸": "muntaani",
      "൹": "datemark"
    }
  },

  "conjuncts_ka": {
    "description": {"en": "Conjuncts ക"},
    "glyph_map": {
      "കു": "k1u1",
      "കൂ": "k1u2",
      "കൃ": "k1r1",
      "കൄ": "k1r2",
      "ക്ക": "k1k1",
      "ക്കു": "k1k1u1",
      "ക്കൂ": "k1k1u2",
      "ക്കൃ": "k1k1r1",
      "ക്ക്ര": "k1k1r3",
      "ക്ക്രു": "k1k1r3u1",
      "ക്ക്രൂ": "k1k1r3u2",
      "ക്ട": "k1t1",
      "ക്ടു": "k1t1u1",
      "ക്ടൂ": "k1t1u2",
      "ക്ട്ര": "k1t1r3",
      "ക്ട്രു": "k1t1r3u1",
      "ക്ട്രൂ": "k1t1r3u2",
      "ക്ണ": "k1nh",
      "ക്ണു": "k1nhu1",
      "ക്ണൂ": "k1nhu2",
      "ക്ത": "k1th1",
      "ക്തു": "k1th1u1",
      "ക്തൂ": "k1th1u2",
      "ക്തൃ": "k1th1r1",
      "ക്തൄ": "k1th1r2",
      "ക്ത്ര": "k1th1r3",
      "ക്ത്രു": "k1th1r3u1",
      "ക്ത്രൂ": "k1th1r3u2",
      "ക്ന": "k1n1",
      "ക്നു": "k1n1u1",
      "ക്നൂ": "k1n1u2",
      "ക്മ": "k1m1",
      "ക്മു": "k1m1u1",
      "ക്മൂ": "k1m1u2",
      "ക്ര": "k1r3",
      "ക്രു": "k1r3u1",
      "ക്രൂ": "k1r3u2",
      "ക്റ്റ": "k1rhrh",
      "ക്റ്റു": "k1rhrhu1",
      "ക്റ്റൂ": "k1rhrhu2",
      "ക്ല": "k1l3",
      "ക്ലു": "k1l3u1",
      "ക്ലൂ": "k1l3u2",
      "ക്ഷ": "k1sh",
      "ക്ഷു": "k1shu1",
      "ക്ഷൂ": "k1shu2",
      "ക്ഷൃ": "k1shr1",
      "ക്ഷ്ണ": "k1shnh",
      "ക്ഷ്ണു": "k1shnhu1",
      "ക്ഷ്ണൂ": "k1shnhu2",
      "ക്ഷ്മ": "k1shm1",
      "ക്ഷ്മു": "k1shm1u1",
      "ക്ഷ്മൂ": "k1shm1u2",
      "ക്ഷ്മൃ": "k1shm1r1",
      "ക്സ": "k1s1",
      "ക്സു": "k1s1u1",
      "ക്സൂ": "k1s1u2",
      "ക്സൃ": "k1s1r1",
      "കൢ": "k1l1",
      "ഖു": "k2u1",
      "ഖൂ": "k2u2",
      "ഖൃ": "k2r1",
      "ഗു": "k3u1",
      "ഗൂ": "k3u2",
      "ഗൃ": "k3r1",
      "ഗൄ": "k3r2",
      "ഗ്ഗ": "k3k3",
      "ഗ്ഗു": "k3k3u1",
      "ഗ്ഗൂ": "k3k3u2",
      "ഗ്ഗൃ": "k3k3r1",
      "ഗ്ഘ": "k3k4",
      "ഗ്ഘു": "k3k4u1",
      "ഗ്ഘൂ": "k3k4u2",
      "ഗ്ഘൃ": "k3k4r1",
      "ഗ്ദ": "k3th3",
      "ഗ്ദു": "k3th3u1",
      "ഗ്ദൂ": "k3th3u2",
      "ഗ്ദൃ": "k3th3r1",
      "ഗ്ദ്ധ": "k3th3th4",
      "ഗ്ദ്ധു": "k3th3th4u1",
      "ഗ്ദ്ധൂ": "k3th3th4u2",
      "ഗ്ദ്ധൃ": "k3th3th4r1",
      "ഗ്ദ്ധ്ര": "k3th3th4r3",
      "ഗ്ദ്ധ്രു": "k3th3th4r3u1",
      "ഗ്ദ്ധ്രൂ": "k3th3th4r3u2",
      "ഗ്ന": "k3n1",
      "ഗ്നു": "k3n1u1",
      "ഗ്നൂ": "k3n1u2",
      "ഗ്മ": "k3m1",
      "ഗ്മു": "k3m1u1",
      "ഗ്മൂ": "k3m1u2",
      "ഗ്ര": "k3r3",
      "ഗ്രു": "k3r3u1",
      "ഗ്രൂ": "k3r3u2",
      "ഗ്ല": "k3l3",
      "ഗ്ലു": "k3l3u1",
      "ഗ്ലൂ": "k3l3u2",
      "ഘു": "k4u1",
      "ഘൂ": "k4u2",
      "ഘൃ": "k4r1",
      "ഘൄ": "k4r2",
      "ഘ്ന": "k4n1",
      "ഘ്നു": "k4n1u1",
      "ഘ്നൂ": "k4n1u2",
      "ഘ്ര": "k4r3",
      "ഘ്രു": "k4r3u1",
      "ഘ്രൂ": "k4r3u2",
      "ങു": "ngu1",
      "ങൂ": "ngu2",
      "ങ്ക": "ngk1",
      "ങ്കു": "ngk1u1",
      "ങ്കൂ": "ngk1u2",
      "ങ്കൃ": "ngk1r1",
      "ങ്ങ": "ngng",
      "ങ്ങു": "ngngu1",
      "ങ്ങൂ": "ngngu2"
    }
  },

  "conjuncts_cha": {
    "description": {"en": "Conjuncts ച"},
    "glyph_map": {
      "ചു": "ch1u1",
      "ചൂ": "ch1u2",
      "ച്ച": "ch1ch1",
      "ച്ചു": "ch1ch1u1",
      "ച്ചൂ": "ch1ch1u2",
      "ച്ഛ": "ch1ch2",
      "ച്ഛു": "ch1ch2u1",
      "ച്ഛൂ": "ch1ch2u2",
      "ച്ഛൃ": "ch1ch2r1",
      "ച്ഛ്ര": "ch1ch2r3",
      "ച്ഛ്രു": "ch1ch2r3u1",
      "ച്ഛ്രൂ": "ch1ch2r3u2",
      "ഛു": "ch2u1",
      "ഛൂ": "ch2u2",
      "ഛൃ": "ch2r1",
      "ജു": "ch3u1",
      "ജൂ": "ch3u2",
      "ജൃ": "ch3r1",
      "ജൄ": "ch3r2",
      "ജ്ജ": "ch3ch3",
      "ജ്ജു": "ch3ch3u1",
      "ജ്ജൂ": "ch3ch3u2",
      "ജ്ജൃ": "ch3ch3r1",
      "ജ്ഞ": "ch3nj",
      "ജ്ഞു": "ch3nju1",
      "ജ്ഞൂ": "ch3nju2",
      "ജ്ര": "ch3r3",
      "ജ്രു": "ch3r3u1",
      "ജ്രൂ": "ch3r3u2",
      "ഝു": "ch4u1",
      "ഝൂ": "ch4u2",
      "ഝൃ": "ch4r1",
      "ഝൄ": "ch4r2",
      "ഞു": "nju1",
      "ഞൂ": "nju2",
      "ഞ്ച": "njch1",
      "ഞ്ചു": "njch1u1",
      "ഞ്ചൂ": "njch1u2",
      "ഞ്ചൃ": "njch1r1",
      "ഞ്ഛ": "njch2",
      "ഞ്ഛു": "njch2u1",
      "ഞ്ഛൂ": "njch2u2",
      "ഞ്ഛൃ": "njch2r1",
      "ഞ്ജ": "njch3",
      "ഞ്ജു": "njch3u1",
      "ഞ്ജൂ": "njch3u2",
      "ഞ്ഞ": "njnj",
      "ഞ്ഞു": "njnju1",
      "ഞ്ഞൂ": "njnju2"
    }
  },

  "conjuncts_ta": {
    "description": {"en": "Conjuncts ട"},
    "glyph_map": {
      "ടു": "t1u1",
      "ടൂ": "t1u2",
      "ടൃ": "t1r1",
      "ടൄ": "t1r2",
      "ട്ട": "t1t1",
      "ട്ടു": "t1t1u1",
      "ട്ടൂ": "t1t1u2",
      "ട്ര": "t1r3",
      "ട്രു": "t1r3u1",
      "ട്രൂ": "t1r3u2",
      "ഠു": "t2u1",
      "ഠൂ": "t2u2",
      "ഡു": "t3u1",
      "ഡൂ": "t3u2",
      "ഡൃ": "t3r1",
      "ഡ്ഡ": "t3t3",
      "ഡ്ഡു": "t3t3u1",
      "ഡ്ഡൂ": "t3t3u2",
      "ഡ്ഢ": "t3t4",
      "ഡ്ഢു": "t3t4u1",
      "ഡ്ഢൂ": "t3t4u2",
      "ഡ്മ": "t3m1",
      "ഡ്മു": "t3m1u1",
      "ഡ്മൂ": "t3m1u2",
      "ഡ്മൃ": "t3m1r1",
      "ഡ്ര": "t3r3",
      "ഡ്രു": "t3r3u1",
      "ഡ്രൂ": "t3r3u2",
      "ഢു": "t4u1",
      "ഢൂ": "t4u2",
      "ഢൃ": "t4r1",
      "ഢ്ര": "t4r3",
      "ഢ്രു": "t4r3u1",
      "ഢ്രൂ": "t4r3u2",
      "ണു": "nhu1",
      "ണൂ": "nhu2",
      "ണ്ട": "nht1",
      "ണ്ടു": "nht1u1",
      "ണ്ടൂ": "nht1u2",
      "ണ്ട്ര": "nht1r3",
      "ണ്ട്രു": "nht1r3u1",
      "ണ്ട്രൂ": "nht1r3u2",
      "ണ്ഠ": "nht2",
      "ണ്ഡ": "nht3",
      "ണ്ഡു": "nht3u1",
      "ണ്ഡൂ": "nht3u2",
      "ണ്ഡൃ": "nht3r1",
      "ണ്ഡ്ര": "nht3r3",
      "ണ്ഡ്രു": "nht3r3u1",
      "ണ്ഡ്രൂ": "nht3r3u2",
      "ണ്ഢ": "nht4",
      "ണ്ഢു": "nht4u1",
      "ണ്ഢൂ": "nht4u2",
      "ണ്ഢൃ": "nht4r1",
      "ണ്ണ": "nhnh",
      "ണ്ണു": "nhnhu1",
      "ണ്ണൂ": "nhnhu2",
      "ണ്മ": "nhm1",
      "ണ്മു": "nhm1u1",
      "ണ്മൂ": "nhm1u2"
    }
  },

  "conjuncts_tha": {
    "description": {"en": "Conjuncts ത"},
    "glyph_map": {
      "തു": "th1u1",
      "തൂ": "th1u2",
      "തൃ": "th1r1",
      "തൄ": "th1r2",
      "ത്ത": "th1th1",
      "ത്തു": "th1th1u1",
      "ത്തൂ": "th1th1u2",
      "ത്തൃ": "th1th1r1",
      "ത്തൄ": "th1th1r2",
      "ത്ത്ര": "th1th1r3",
      "ത്ത്രു": "th1th1r3u1",
      "ത്ത്രൂ": "th1th1r3u2",
      "ത്ഥ": "th1th2",
      "ത്ഥു": "th1th2u1",
      "ത്ഥൂ": "th1th2u2",
      "ത്ഥൃ": "th1th2r1",
      "ത്ന": "th1n1",
      "ത്നു": "th1n1u1",
      "ത്നൂ": "th1n1u2",
      "ത്ഭ": "th1p4",
      "ത്ഭു": "th1p4u1",
      "ത്ഭൂ": "th1p4u2",
      "ത്ഭൃ": "th1p4r1",
      "ത്മ": "th1m1",
      "ത്മു": "th1m1u1",
      "ത്മൂ": "th1m1u2",
      "ത്മൃ": "th1m1r1",
      "ത്ര": "th1r3",
      "ത്രു": "th1r3u1",
      "ത്രൂ": "th1r3u2",
      "ത്ല": "th1l3",
      "ത്ലു": "th1l3u1",
      "ത്ലൂ": "th1l3u2",
      "ത്സ": "th1s1",
      "ത്സു": "th1s1u1",
      "ത്സൂ": "th1s1u2",
      "ത്സൃ": "th1s1r1",
      "ത്സൄ": "th1s1r2",
      "ത്സ്ഥ": "th1s1th2",
      "ത്സ്ഥു": "th1s1th2u1",
      "ത്സ്ഥൂ": "th1s1th2u2",
      "ത്സ്ഥൃ": "th1s1th2r1",
      "ത്സ്ന": "th1s1n1",
      "ത്സ്നു": "th1s1n1u1",
      "ത്സ്നൂ": "th1s1n1u2",
      "ത്സ്മ": "th1s1m1",
      "ത്സ്മു": "th1s1m1u1",
      "ത്സ്മൂ": "th1s1m1u2",
      "ത്സ്മൃ": "th1s1m1r1",
      "ത്സ്ര": "th1s1r3",
      "ത്സ്രു": "th1s1r3u1",
      "ത്സ്രൂ": "th1s1r3u2",
      "ഥു": "th2u1",
      "ഥൂ": "th2u2",
      "ഥ്ന": "th2n1",
      "ഥ്നു": "th2n1u1",
      "ഥ്നൂ": "th2n1u2",
      "ഥ്മ": "th2m1",
      "ഥ്മു": "th2m1u1",
      "ഥ്മൂ": "th2m1u2",
      "ഥ്മൃ": "th2m1r1",
      "ദു": "th3u1",
      "ദൂ": "th3u2",
      "ദൃ": "th3r1",
      "ദൄ": "th3r2",
      "ദ്ദ": "th3th3",
      "ദ്ദു": "th3th3u1",
      "ദ്ദൂ": "th3th3u2",
      "ദ്ദൃ": "th3th3r1",
      "ദ്ദൄ": "th3th3r2",
      "ദ്ധ": "th3th4",
      "ദ്ധു": "th3th4u1",
      "ദ്ധൂ": "th3th4u2",
      "ദ്ധൃ": "th3th4r1",
      "ദ്ധൄ": "th3th4r2",
      "ദ്ധ്ന": "th3th4n1",
      "ദ്ധ്നു": "th3th4n1u1",
      "ദ്ധ്നൂ": "th3th4n1u2",
      "ദ്ധ്മ": "th3th4m1",
      "ദ്ധ്മു": "th3th4m1u1",
      "ദ്ധ്മൂ": "th3th4m1u2",
      "ദ്ധ്മൃ": "th3th4m1r1",
      "ദ്ധ്ര": "th3th4r3",
      "ദ്ധ്രു": "th3th4r3u1",
      "ദ്ധ്രൂ": "th3th4r3u2",
      "ദ്ര": "th3r3",
      "ദ്രു": "th3r3u1",
      "ദ്രൂ": "th3r3u2",
      "ധു": "th4u1",
      "ധൂ": "th4u2",
      "ധൃ": "th4r1",
      "ധൄ": "th4r2",
      "ധ്ന": "th4n1",
      "ധ്നു": "th4n1u1",
      "ധ്നൂ": "th4n1u2",
      "ധ്മ": "th4m1",
      "ധ്മു": "th4m1u1",
      "ധ്മൂ": "th4m1u2",
      "ധ്മൃ": "th4m1r1",
      "ധ്ര": "th4r3",
      "ധ്രു": "th4r3u1",
      "ധ്രൂ": "th4r3u2",
      "നു": "n1u1",
      "നൂ": "n1u2",
      "നൃ": "n1r1",
      "നൄ": "n1r2",
      "ന്ത": "n1th1",
      "ന്തു": "n1th1u1",
      "ന്തൂ": "n1th1u2",
      "ന്തൃ": "n1th1r1",
      "ന്തൄ": "n1th1r2",
      "ന്ത്ര": "n1th1r3",
      "ന്ത്രു": "n1th1r3u1",
      "ന്ത്രൂ": "n1th1r3u2",
      "ന്ഥ": "n1th2",
      "ന്ഥു": "n1th2u1",
      "ന്ഥൂ": "n1th2u2",
      "ന്ഥൃ": "n1th2r1",
      "ന്ഥൄ": "n1th2r2",
      "ന്ദ": "n1th3",
      "ന്ദു": "n1th3u1",
      "ന്ദൂ": "n1th3u2",
      "ന്ദൃ": "n1th3r1",
      "ന്ദ്ര": "n1th3r3",
      "ന്ദ്രു": "n1th3r3u1",
      "ന്ദ്രൂ": "n1th3r3u2",
      "ന്ധ": "n1th4",
      "ന്ധു": "n1th4u1",
      "ന്ധൂ": "n1th4u2",
      "ന്ധൃ": "n1th4r1",
      "ന്ധ്ര": "n1th4r3",
      "ന്ധ്രു": "n1th4r3u1",
      "ന്ധ്രൂ": "n1th4r3u2",
      "ന്ന": "n1n1",
      "ന്നു": "n1n1u1",
      "ന്നൂ": "n1n1u2",
      "ന്നൃ": "n1n1r1",
      "ന്ന്ര": "n1n1r3",
      "ന്ന്രു": "n1n1r3u1",
      "ന്ന്രൂ": "n1n1r3u2",
      "ന്മ": "n1m1",
      "ന്മു": "n1m1u1",
      "ന്മൂ": "n1m1u2",
      "ന്മൃ": "n1m1r1",
      "ന്മ്ര": "n1m1r3",
      "ന്മ്രു": "n1m1r3u1",
      "ന്മ്രൂ": "n1m1r3u2",
      "ന്ര": "n1r3",
      "ന്രു": "n1r3u1",
      "ന്രൂ": "n1r3u2",
      "ന്റ": "n1rh",
      "ന്റു": "n1rhu1",
      "ന്റൂ": "n1rhu2"
    }
  },

  "conjuncts_pa": {
    "description": {"en": "Conjuncts പ"},
    "glyph_map": {
      "പു": "p1u1",
      "പൂ": "p1u2",
      "പൃ": "p1r1",
      "പൄ": "p1r2",
      "പ്ട": "p1t1",
      "പ്ടു": "p1t1u1",
      "പ്ടൂ": "p1t1u2",
      "പ്ത": "p1th1",
      "പ്തു": "p1th1u1",
      "പ്തൂ": "p1th1u2",
      "പ്തൃ": "p1th1r1",
      "പ്തൄ": "p1th1r2",
      "പ്ന": "p1n1",
      "പ്നു": "p1n1u1",
      "പ്നൂ": "p1n1u2",
      "പ്പ": "p1p1",
      "പ്പു": "p1p1u1",
      "പ്പൂ": "p1p1u2",
      "പ്പൃ": "p1p1r1",
      "പ്ഫ": "p1p2",
      "പ്ഫു": "p1p2u1",
      "പ്ഫൂ": "p1p2u2",
      "പ്ര": "p1r3",
      "പ്രു": "p1r3u1",
      "പ്രൂ": "p1r3u2",
      "പ്ല": "p1l3",
      "പ്ലു": "p1l3u1",
      "പ്ലൂ": "p1l3u2",
      "പ്സ": "p1s1",
      "പ്സു": "p1s1u1",
      "പ്സൂ": "p1s1u2",
      "പ്സൃ": "p1s1r1",
      "പ്സ്ര": "p1s1r3",
      "പ്സ്രു": "p1s1r3u1",
      "പ്സ്രൂ": "p1s1r3u2",
      "ഫു": "p2u1",
      "ഫൂ": "p2u2",
      "ഫ്ക": "p2k1",
      "ഫ്കു": "p2k1u1",
      "ഫ്കൂ": "p2k1u2",
      "ഫ്ട": "p2t1",
      "ഫ്ടു": "p2t1u1",
      "ഫ്ടൂ": "p2t1u2",
      "ഫ്ത": "p2th1",
      "ഫ്തു": "p2th1u1",
      "ഫ്തൂ": "p2th1u2",
      "ഫ്തൃ": "p2th1r1",
      "ഫ്ന": "p2n1",
      "ഫ്നു": "p2n1u1",
      "ഫ്നൂ": "p2n1u2",
      "ഫ്പ": "p2p1",
      "ഫ്പു": "p2p1u1",
      "ഫ്പൂ": "p2p1u2",
      "ഫ്മ": "p2m1",
      "ഫ്മു": "p2m1u1",
      "ഫ്മൂ": "p2m1u2",
      "ഫ്ര": "p2r3",
      "ഫ്രു": "p2r3u1",
      "ഫ്രൂ": "p2r3u2",
      "ഫ്റ്റ": "p2rhrh",
      "ഫ്റ്റു": "p2rhrhu1",
      "ഫ്റ്റൂ": "p2rhrhu2",
      "ഫ്ല": "p2l3",
      "ഫ്ലു": "p2l3u1",
      "ഫ്ലൂ": "p2l3u2",
      "ഫ്സ": "p2s1",
      "ഫ്സു": "p2s1u1",
      "ഫ്സൂ": "p2s1u2",
      "ഫ്സൃ": "p2s1r1",
      "ബു": "p3u1",
      "ബൂ": "p3u2",
      "ബൃ": "p3r1",
      "ബ്ഗ": "p3k3",
      "ബ്ഗു": "p3k3u1",
      "ബ്ഗൂ": "p3k3u2",
      "ബ്ഗൃ": "p3k3r1",
      "ബ്ജ": "p3ch3",
      "ബ്ജു": "p3ch3u1",
      "ബ്ജൂ": "p3ch3u2",
      "ബ്ജൃ": "p3ch3r1",
      "ബ്ദ": "p3th3",
      "ബ്ദു": "p3th3u1",
      "ബ്ദൂ": "p3th3u2",
      "ബ്ദൃ": "p3th3r1",
      "ബ്ധ": "p3th4",
      "ബ്ധു": "p3th4u1",
      "ബ്ധൂ": "p3th4u2",
      "ബ്ധൃ": "p3th4r1",
      "ബ്ധൄ": "p3th4r2",
      "ബ്ന": "p3n1",
      "ബ്നു": "p3n1u1",
      "ബ്നൂ": "p3n1u2",
      "ബ്ബ": "p3p3",
      "ബ്ബു": "p3p3u1",
      "ബ്ബൂ": "p3p3u2",
      "ബ്ഭ": "p3p4",
      "ബ്ഭു": "p3p4u1",
      "ബ്ഭൂ": "p3p4u2",
      "ബ്ര": "p3r3",
      "ബ്രു": "p3r3u1",
      "ബ്രൂ": "p3r3u2",
      "ബ്ല": "p3l3",
      "ബ്ലു": "p3l3u1",
      "ബ്ലൂ": "p3l3u2",
      "ഭു": "p4u1",
      "ഭൂ": "p4u2",
      "ഭൃ": "p4r1",
      "ഭൄ": "p4r2",
      "ഭ്ര": "p4r3",
      "ഭ്രു": "p4r3u1",
      "ഭ്രൂ": "p4r3u2",
      "മു": "m1u1",
      "മൂ": "m1u2",
      "മൃ": "m1r1",
      "മൄ": "m1r2",
      "മ്ന": "m1n1",
      "മ്നു": "m1n1u1",
      "മ്നൂ": "m1n1u2",
      "മ്നൃ": "m1n1r1",
      "മ്പ": "m1p1",
      "മ്പു": "m1p1u1",
      "മ്പൂ": "m1p1u2",
      "മ്പൃ": "m1p1r1",
      "മ്പ്ര": "m1p1r3",
      "മ്പ്രു": "m1p1r3u1",
      "മ്പ്രൂ": "m1p1r3u2",
      "മ്മ": "m1m1",
      "മ്മു": "m1m1u1",
      "മ്മൂ": "m1m1u2",
      "മ്മൃ": "m1m1r1",
      "മ്ര": "m1r3",
      "മ്രു": "m1r3u1",
      "മ്രൂ": "m1r3u2",
      "മ്ല": "m1l3",
      "മ്ലു": "m1l3u1",
      "മ്ലൂ": "m1l3u2"
    }
  },

  "conjuncts_ya_ra_la_va": {
    "description": {"en": "Conjuncts യ/ര/ല/വ"},
    "glyph_map": {
      "യു": "y1u1",
      "യൂ": "y1u2",
      "യൃ": "y1r1",
      "യ്ക": "y1k1",
      "യ്കു": "y1k1u1",
      "യ്കൂ": "y1k1u2",
      "യ്കൃ": "y1k1r1",
      "യ്ക്ക": "y1k1k1",
      "യ്ക്കു": "y1k1k1u1",
      "യ്ക്കൂ": "y1k1k1u2",
      "യ്ച": "y1ch1",
      "യ്ചു": "y1ch1u1",
      "യ്ചൂ": "y1ch1u2",
      "യ്ത": "y1th1",
      "യ്തു": "y1th1u1",
      "യ്തൂ": "y1th1u2",
      "യ്തൃ": "y1th1r1",
      "യ്ത്ത": "y1th1th1",
      "യ്ത്തു": "y1th1th1u1",
      "യ്ത്തൂ": "y1th1th1u2",
      "യ്ത്തൃ": "y1th1th1r1",
      "യ്ന": "y1n1",
      "യ്നു": "y1n1u1",
      "യ്നൂ": "y1n1u2",
      "യ്പ": "y1p1",
      "യ്പു": "y1p1u1",
      "യ്പൂ": "y1p1u2",
      "യ്പൃ": "y1p1r1",
      "യ്മ": "y1m1",
      "യ്മു": "y1m1u1",
      "യ്മൂ": "y1m1u2",
      "യ്മൃ": "y1m1r1",
      "യ്യ": "y1y1",
      "യ്യു": "y1y1u1",
      "യ്യൂ": "y1y1u2",
      "രു": "r3u1",
      "രൂ": "r3u2",
      "രൃ": "r3r1",
      "ലു": "l3u1",
      "ലൂ": "l3u2",
      "ലൃ": "l3r1",
      "ല്ക": "l3k1",
      "ല്കു": "l3k1u1",
      "ല്കൂ": "l3k1u2",
      "ല്കൃ": "l3k1r1",
      "ല്ക്ക": "l3k1k1",
      "ല്ക്കു": "l3k1k1u1",
      "ല്ക്കൂ": "l3k1k1u2",
      "ല്ഗ": "l3k3",
      "ല്ഗു": "l3k3u1",
      "ല്ഗൂ": "l3k3u2",
      "ല്ഗൃ": "l3k3r1",
      "ല്ച": "l3ch1",
      "ല്ചു": "l3ch1u1",
      "ല്ചൂ": "l3ch1u2",
      "ല്ത": "l3th1",
      "ല്തു": "l3th1u1",
      "ല്തൂ": "l3th1u2",
      "ല്തൃ": "l3th1r1",
      "ല്ത്ത": "l3th1th1",
      "ല്ത്തു": "l3th1th1u1",
      "ല്ത്തൂ": "l3th1th1u2",
      "ല്പ": "l3p1",
      "ല്പു": "l3p1u1",
      "ല്പൂ": "l3p1u2",
      "ല്ഫ": "l3p2",
      "ല്ഫു": "l3p2u1",
      "ല്ഫൂ": "l3p2u2",
      "ല്ബ": "l3p3",
      "ല്ബു": "l3p3u1",
      "ല്ബൂ": "l3p3u2",
      "ല്മ": "l3m1",
      "ല്മു": "l3m1u1",
      "ല്മൂ": "l3m1u2",
      "ല്ല": "l3l3",
      "ല്ലു": "l3l3u1",
      "ല്ലൂ": "l3l3u2",
      "വു": "v1u1",
      "വൂ": "v1u2",
      "വൃ": "v1r1",
      "വ്ര": "v1r3",
      "വ്രു": "v1r3u1",
      "വ്രൂ": "v1r3u2",
      "വ്ല": "v1l3",
      "വ്ലു": "v1l3u1",
      "വ്ലൂ": "v1l3u2",
      "വ്വ": "v1v1",
      "വ്വു": "v1v1u1",
      "വ്വൂ": "v1v1u2"
    }
  },

  "conjuncts_za_sha_sa_ha": {
    "description": {"en": "Conjuncts ശ/ഷ/സ/ഹ"},
    "glyph_map": {
      "ശു": "z1u1",
      "ശൂ": "z1u2",
      "ശൃ": "z1r1",
      "ശ്ച": "z1ch1",
      "ശ്ചു": "z1ch1u1",
      "ശ്ചൂ": "z1ch1u2",
      "ശ്ചൃ": "z1ch1r1",
      "ശ്ഛ": "z1ch2",
      "ശ്ഛു": "z1ch2u1",
      "ശ്ഛൂ": "z1ch2u2",
      "ശ്ഛൃ": "z1ch2r1",
      "ശ്ന": "z1n1",
      "ശ്നു": "z1n1u1",
      "ശ്നൂ": "z1n1u2",
      "ശ്മ": "z1m1",
      "ശ്മു": "z1m1u1",
      "ശ്മൂ": "z1m1u2",
      "ശ്മൃ": "z1m1r1",
      "ശ്ര": "z1r3",
      "ശ്രു": "z1r3u1",
      "ശ്രൂ": "z1r3u2",
      "ശ്ല": "z1l3",
      "ശ്ലു": "z1l3u1",
      "ശ്ലൂ": "z1l3u2",
      "ശ്ശ": "z1z1",
      "ശ്ശു": "z1z1u1",
      "ശ്ശൂ": "z1z1u2",
      "ശ്ശൃ": "z1z1r1",
      "ശ്ശ്ര": "z1z1r3",
      "ശ്ശ്രു": "z1z1r3u1",
      "ശ്ശ്രൂ": "z1z1r3u2",
      "ഷു": "shu1",
      "ഷൂ": "shu2",
      "ഷൃ": "shr1",
      "ഷ്ക": "shk1",
      "ഷ്കു": "shk1u1",
      "ഷ്കൂ": "shk1u2",
      "ഷ്കൃ": "shk1r1",
      "ഷ്ക്ക": "shk1k1",
      "ഷ്ക്കു": "shk1k1u1",
      "ഷ്ക്കൂ": "shk1k1u2",
      "ഷ്ക്കൃ": "shk1k1r1",
      "ഷ്ക്ര": "shk1r3",
      "ഷ്ക്രു": "shk1r3u1",
      "ഷ്ക്രൂ": "shk1r3u2",
      "ഷ്ട": "sht1",
      "ഷ്ടു": "sht1u1",
      "ഷ്ടൂ": "sht1u2",
      "ഷ്ടൃ": "sht1r1",
      "ഷ്ടൄ": "sht1r2",
      "ഷ്ട്ര": "sht1r3",
      "ഷ്ട്രു": "sht1r3u1",
      "ഷ്ട്രൂ": "sht1r3u2",
      "ഷ്ഠ": "sht2",
      "ഷ്ഠു": "sht2u1",
      "ഷ്ഠൂ": "sht2u2",
      "ഷ്ണ": "shnh",
      "ഷ്ണു": "shnhu1",
      "ഷ്ണൂ": "shnhu2",
      "ഷ്പ": "shp1",
      "ഷ്പു": "shp1u1",
      "ഷ്പൂ": "shp1u2",
      "ഷ്പൃ": "shp1r1",
      "ഷ്പ്ര": "shp1r3",
      "ഷ്പ്രു": "shp1r3u1",
      "ഷ്പ്രൂ": "shp1r3u2",
      "ഷ്ഫ": "shp2",
      "ഷ്ഫു": "shp2u1",
      "ഷ്ഫൂ": "shp2u2",
      "ഷ്മ": "shm1",
      "ഷ്മു": "shm1u1",
      "ഷ്മൂ": "shm1u2",
      "ഷ്ര": "shr3",
      "ഷ്രു": "shr3u1",
      "ഷ്രൂ": "shr3u2",
      "സു": "s1u1",
      "സൂ": "s1u2",
      "സൃ": "s1r1",
      "സൄ": "s1r2",
      "സ്ക": "s1k1",
      "സ്കു": "s1k1u1",
      "സ്കൂ": "s1k1u2",
      "സ്കൃ": "s1k1r1",
      "സ്ക്ക": "s1k1k1",
      "സ്ക്കു": "s1k1k1u1",
      "സ്ക്കൂ": "s1k1k1u2",
      "സ്ക്കൃ": "s1k1k1r1",
      "സ്ക്ര": "s1k1r3",
      "സ്ക്രു": "s1k1r3u1",
      "സ്ക്രൂ": "s1k1r3u2",
      "സ്ഖ": "s1k2",
      "സ്ഖു": "s1k2u1",
      "സ്ഖൂ": "s1k2u2",
      "സ്ട": "s1t1",
      "സ്ടു": "s1t1u1",
      "സ്ടൂ": "s1t1u2",
      "സ്ട്ര": "s1t1r3",
      "സ്ട്രു": "s1t1r3u1",
      "സ്ട്രൂ": "s1t1r3u2",
      "സ്ത": "s1th1",
      "സ്തു": "s1th1u1",
      "സ്തൂ": "s1th1u2",
      "സ്തൃ": "s1th1r1",
      "സ്തൄ": "s1th1r2",
      "സ്ത്ര": "s1th1r3",
      "സ്ത്രു": "s1th1r3u1",
      "സ്ത്രൂ": "s1th1r3u2",
      "സ്ഥ": "s1th2",
      "സ്ഥു": "s1th2u1",
      "സ്ഥൂ": "s1th2u2",
      "സ്ഥൃ": "s1th2r1",
      "സ്ന": "s1n1",
      "സ്നു": "s1n1u1",
      "സ്നൂ": "s1n1u2",
      "സ്പ": "s1p1",
      "സ്പു": "s1p1u1",
      "സ്പൂ": "s1p1u2",
      "സ്പൃ": "s1p1r1",
      "സ്പ്ര": "s1p1r3",
      "സ്പ്രു": "s1p1r3u1",
      "സ്പ്രൂ": "s1p1r3u2",
      "സ്ഫ": "s1p2",
      "സ്ഫു": "s1p2u1",
      "സ്ഫൂ": "s1p2u2",
      "സ്മ": "s1m1",
      "സ്മു": "s1m1u1",
      "സ്മൂ": "s1m1u2",
      "സ്മൃ": "s1m1r1",
      "സ്ര": "s1r3",
      "സ്രു": "s1r3u1",
      "സ്രൂ": "s1r3u2",
      "സ്റ്റ": "s1rhrh",
      "സ്റ്റു": "s1rhrhu1",
      "സ്റ്റൂ": "s1rhrhu2",
      "സ്റ്റ്ര": "s1rhrhr3",
      "സ്റ്റ്രു": "s1rhrhr3u1",
      "സ്റ്റ്രൂ": "s1rhrhr3u2",
      "സ്ല": "s1l3",
      "സ്ലു": "s1l3u1",
      "സ്ലൂ": "s1l3u2",
      "സ്സ": "s1s1",
      "സ്സു": "s1s1u1",
      "സ്സൂ": "s1s1u2",
      "സ്സൃ": "s1s1r1",
      "സ്സ്ര": "s1s1r3",
      "സ്സ്രു": "s1s1r3u1",
      "സ്സ്രൂ": "s1s1r3u2",
      "ഹു": "h1u1",
      "ഹൂ": "h1u2",
      "ഹൃ": "h1r1",
      "ഹ്ന": "h1n1",
      "ഹ്നു": "h1n1u1",
      "ഹ്നൂ": "h1n1u2",
      "ഹ്മ": "h1m1",
      "ഹ്മു": "h1m1u1",
      "ഹ്മൂ": "h1m1u2",
      "ഹ്മൃ": "h1m1r1",
      "ഹ്ര": "h1r3",
      "ഹ്രു": "h1r3u1",
      "ഹ്രൂ": "h1r3u2",
      "ഹ്ല": "h1l3",
      "ഹ്ലു": "h1l3u1",
      "ഹ്ലൂ": "h1l3u2"
    }
  },

  "conjuncts_lha_zha_rha": {
    "description": {"en": "Conjuncts ള/ഴ/റ"},
    "glyph_map": {
      "ളു": "lhu1",
      "ളൂ": "lhu2",
      "ള്ള": "lhlh",
      "ള്ളു": "lhlhu1",
      "ള്ളൂ": "lhlhu2",
      "ഴു": "zhu1",
      "ഴൂ": "zhu2",
      "ഴ്ക": "zhk1",
      "ഴ്കു": "zhk1u1",
      "ഴ്കൂ": "zhk1u2",
      "ഴ്ക്ക": "zhk1k1",
      "ഴ്ക്കു": "zhk1k1u1",
      "ഴ്ക്കൂ": "zhk1k1u2",
      "ഴ്ച": "zhch1",
      "ഴ്ചു": "zhch1u1",
      "ഴ്ചൂ": "zhch1u2",
      "ഴ്ത": "zhth1",
      "ഴ്തു": "zhth1u1",
      "ഴ്തൂ": "zhth1u2",
      "ഴ്ത്ത": "zhth1th1",
      "ഴ്ത്തു": "zhth1th1u1",
      "ഴ്ത്തൂ": "zhth1th1u2",
      "ഴ്ന": "zhn1",
      "ഴ്നു": "zhn1u1",
      "ഴ്നൂ": "zhn1u2",
      "ഴ്ന്ന": "zhn1n1",
      "ഴ്ന്നു": "zhn1n1u1",
      "ഴ്ന്നൂ": "zhn1n1u2",
      "ഴ്പ": "zhp1",
      "ഴ്പു": "zhp1u1",
      "ഴ്പൂ": "zhp1u2",
      "ഴ്മ": "zhm1",
      "ഴ്മു": "zhm1u1",
      "ഴ്മൂ": "zhm1u2",
      "ഴ്വ": "zhv1",
      "ഴ്വു": "zhv1u1",
      "ഴ്വൂ": "zhv1u2",
      "ഴ്സ": "zhs1",
      "ഴ്സു": "zhs1u1",
      "ഴ്സൂ": "zhs1u2",
      "റു": "rhu1",
      "റൂ": "rhu2",
      "റ്റ": "rhrh",
      "റ്റു": "rhrhu1",
      "റ്റൂ": "rhrhu2"
    }
  }
};

