/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Anchor, Button, PageHeader } from 'grommet';
import { BorderType } from 'grommet/utils';

interface IFontPageHeader {
  title: string;
  subTitle?: string;
  showActionButton?: boolean;
  actionButtonPrimary?: boolean;
  actionButtonSecondary?: boolean;
  actionButtonIcon?: JSX.Element;
  actionButtonText?: string;
  showActionAnchor?: boolean;
  actionAnchorText?: string;
  actionButtonHandler?: () => void;
  actionAnchorHandler?: () => void;
  parent?: JSX.Element;
  border?: BorderType;
  reverseButtonIcon?: boolean;
}

export const FontPageHeader: React.FC<IFontPageHeader> = (props: IFontPageHeader) => {
  const defaults: Partial<IFontPageHeader> = { actionButtonPrimary: true, actionButtonSecondary: false, reverseButtonIcon: false };
  props = { ...defaults, ...props };
  return (
    <PageHeader
      pad="small"
      title={props.title}
      subtitle={props.subTitle}
      border={props.border ?? { color: 'border', side: 'bottom' }}
      parent={props.parent}
      direction="column"
      gap="none"
      actions={
        <>
          {props.showActionButton && (
            <Button
              label={props.actionButtonText}
              primary={props.actionButtonPrimary}
              icon={props.actionButtonIcon}
              secondary={props.actionButtonSecondary}
              reverse={props.reverseButtonIcon}
              onClick={props.actionButtonHandler}
            />
          )}
          {props.showActionAnchor && <Anchor onClick={props.actionAnchorHandler}>{props.actionAnchorText}</Anchor>}
        </>
      }
    />
  );
};
