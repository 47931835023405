import React, { useState, useEffect } from 'react';
import { useStores } from '../../stores/use-stores';
import { PageSpinner } from '../sharedComponents';
import { fileNameMappings } from '../../utils/fileNameMapping';
import { Accordion, Card } from 'react-bootstrap';
import { Button } from 'grommet';
import { useDropzone } from 'react-dropzone';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FileUploadComponent.scss';
import { Trash } from 'grommet-icons';

interface FileUploadComponentProps {
  userId: number;
  projectId: number;
  closeModal: () => void;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({ userId, projectId, closeModal }) => {
  //const [files, setFiles] = useState<{ [key: string]: { [letter: string]: File } }>(() => (
  const [files, setFiles] = useState<{ [key: string]: { [glyphname: string]: string } }>(() => (
    Object.keys(fileNameMappings).reduce((acc, key) => ({ ...acc, [key]: {} }), {})
  ));

  //const [existingFiles, setExistingFiles] = useState<{ [key: string]: { [letter: string]: File } }>(() => (
  const [existingFiles, setExistingFiles] = useState<{ [key: string]: { [glyphname: string]: string } }>(() => (
    Object.keys(fileNameMappings).reduce((acc, key) => ({ ...acc, [key]: {} }), {})
  ));

  const [activeKey, setActiveKey] = useState<string | null>(null);
  const { apiStore, apiEndpointCallStates } = useStores();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const fetchExistingFiles = async () => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        throw new Error("You are not authorized");
      }
      const response = await apiStore.getSvgFiles(userId, projectId, accessToken);
      const existingSvgFiles = response.files;
      const existingFileKeys = Object.keys(existingSvgFiles);

      const categorizedFiles: any = {  };

      Object.entries(fileNameMappings).forEach(([category, catkeys]) => {
        categorizedFiles[category] = {};
        Object.entries(catkeys.glyph_map).forEach(([letter, glyphname]) => {
          if (existingFileKeys.includes(glyphname)) {
            const svgContent = existingSvgFiles[glyphname];
            //const newFile = new File([svgContent], glyphname, { type: 'image/svg+xml' });
            categorizedFiles[category][glyphname] = svgContent; 
          }
        });
      });

      setExistingFiles(categorizedFiles);
      setFiles(categorizedFiles);
      setActiveKey(Object.keys(fileNameMappings)[0]); 

    } catch (err) {
      setErrorMessage('Failed to load existing files.'); //TODO: this errorMessage is undefined, so error is not shown
    }
  };

  useEffect(() => {
    fetchExistingFiles();
  }, [userId, projectId]);

  const onDrop = (acceptedFiles: File[], category: string, glyphname: string) => {
    const newFiles = acceptedFiles.reduce((acc, file) => {
      if (file.type !== 'image/svg+xml') {
        setErrorMessage(`File "${file.name}" should be an SVG file.`);
        return acc;
      }

      const newFile = new File([file], glyphname + '.svg', { type: file.type });
      return { ...acc, [glyphname]: newFile };
    }, {});

    setFiles(prevFiles => ({
      ...prevFiles,
      [category]: {
        ...prevFiles[category],
        ...newFiles
      }
    }));
  };

  const uploadFiles = async () => {
    if (userId && projectId) {
      const formData = new FormData();

      Object.keys(files).forEach(category => {
        Object.entries(files[category]).forEach(([glyphname, file]) => {
          if (!existingFiles[category][glyphname]) {
            formData.append('files', file, glyphname + '.svg'); // file is of 'Blob' type
          }
        });
      });

      formData.append('user_id', userId.toString());
      formData.append('project_id', projectId.toString());

      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error("You are not authorized");
        }
        await apiStore.uploadFileForProjects(formData, accessToken);
        alert('Files uploaded successfully!');
        await fetchExistingFiles(); // Reload files after upload
      } catch (error) {
        console.error('Error uploading Files:', error);
        let errorMessage = 'Error uploading Files.';
  
        if (error instanceof Error) {
          errorMessage += `: ${error.message}`;
        } else {
          errorMessage += `: ${JSON.stringify(error)}`;
        }
  
        alert(errorMessage);
        }
    } else {
      alert('User ID or Project ID is missing.');
    }
  };

  const handleDelete = (category: string, glyphname: string) => {
    setFiles(prevFiles => {
      const updatedFiles = { ...prevFiles[category] };
      delete updatedFiles[glyphname];
      return {
        ...prevFiles,
        [category]: updatedFiles
      };
    });

    setExistingFiles(prevFiles => {
      const updatedFiles = { ...prevFiles[category] };
      delete updatedFiles[glyphname];
      return {
        ...prevFiles,
        [category]: updatedFiles
      };
    });
  };

  const handleAccordionToggle = (key: string) => {
    setActiveKey(activeKey === key ? null : key);
  };

  const renderLetterList = (category: string) => (
    <div className="letters-container">
      {Object.entries(fileNameMappings[category].glyph_map).map(([letter, glyphname]) => (
        <div key={glyphname} className="letter-cell">
          <DropzoneComponent category={category} glyphname={glyphname} onDrop={onDrop}>
            {files[category][glyphname] ? (
              <img
                //src={URL.createObjectURL(files[category][letter])}
                src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(files[category][glyphname])}`}
                alt={letter}
                className={`uploaded-svg ${existingFiles[category][glyphname] ? 'existing-file' : ''}`}
              />
            ) : (
              <span className={`letter ${existingFiles[category][glyphname] ? 'existing-file' : ''}`}>{letter}</span>
            )}
          </DropzoneComponent>
          {files[category][glyphname] && (
            <Button
              onClick={() => handleDelete(category, glyphname)}
              className="trash-button"
              icon={<Trash size="small" />}
              plain
            />
          )}
          <span className="letter glyphname">{glyphname}</span>
        </div>
      ))}
    </div>
  );

  return (
    <div className="file-upload-component">
      <Button className="close-button" onClick={closeModal}>×</Button>
      <Button onClick={uploadFiles} className="upload-all-button">Save</Button>

      <div className="letters-container">
        {apiEndpointCallStates.uploadFilesInFlight ? (
          <PageSpinner showSpinner={true} />
        ) : (
          <Accordion activeKey={activeKey}>
            {Object.keys(fileNameMappings).map((category, index) => (
              <Card key={category}>
                <Accordion.Header onClick={() => handleAccordionToggle(index.toString())}>
                  {fileNameMappings[category].description.en}
                </Accordion.Header>
                <Accordion.Collapse eventKey={index.toString()}>
                  <Card.Body className="accordion-body-scroll">
                    {renderLetterList(category)}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            ))}
          </Accordion>
        )}
      </div>
    </div>
  );
};

interface DropzoneComponentProps {
  category: string;
  glyphname: string;
  onDrop: (acceptedFiles: File[], category: string, glyphname: string) => void;
  children: React.ReactNode;
}

const DropzoneComponent: React.FC<DropzoneComponentProps> = ({ category, glyphname, onDrop, children }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/svg+xml': ['.svg']
    },
    onDrop: (acceptedFiles) => onDrop(acceptedFiles, category, glyphname),
    multiple: true // Allow multiple files to be dropped
  });

  return (
    <div {...getRootProps()} className="dropzone">
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

export default FileUploadComponent;
