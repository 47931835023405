/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { IFetchContext } from './fetch-context'
import { responseErrorHandler } from './response-error-handler'

export const uploadFileForProjects: (
  fetchContext: IFetchContext,
  body: FormData, // The body should be of type FormData
  accessToken: String
) => Promise<void> = (fetchContext, body, accessToken) => fetch(`${fetchContext.baseUrl}/${body.get('user_id')}/upload_files/${body.get('project_id')}`,
  {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${accessToken}`
    },
    // No need to set Content-Type manually
    body: body // Directly pass the FormData object
  })
  .then((response) => responseErrorHandler<void
  >(fetchContext, response, 'post authenticateUser', true, [], true));
