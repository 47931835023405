/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Box } from 'grommet';
import React from 'react';
import Modal from 'react-modal'
import closeIcon from '../../icons/close.svg';
import './ModalDefault.scss';

interface ModalDefaultProps {
  isModalOpen: boolean;
  customClassName?: string;
  showCloseButton?: boolean;
  headerIcon?: JSX.Element;
  headerIconPath?: string;
  headerText: string;
  onModalCloseButtonClick?: () => void;
  children?: React.ReactNode;
}

const ModalDefaultImpl: React.FC<ModalDefaultProps> = (props) => {
  const { isModalOpen, customClassName, showCloseButton, headerIconPath, headerText, onModalCloseButtonClick, children, headerIcon } =
    props;

  return (
    <Modal isOpen={isModalOpen} className={`modal-default ${customClassName}`}>
      <div className="modal-header">
        <div className="modal-header-inner">
          <>
            {headerIconPath && <img src={headerIconPath} alt={headerText} />}
            <Box margin={{ right: 'small' }}>{headerIcon && headerIcon}</Box>

            <h2>{headerText}</h2>
          </>
        </div>
        {showCloseButton && (
          <button onClick={onModalCloseButtonClick}>
            <img src={closeIcon} alt="close icon" />
          </button>
        )}
      </div>
      <div className="modal-body">{children}</div>
    </Modal>
  );
};
export const ModalDefault = ModalDefaultImpl;
