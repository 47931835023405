/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';

interface SidebarProps {
  userId: number;
  userRole: string;
  onOpenRegistrationModal: () => void; // Add the function type here
}

const Sidebar: React.FC<SidebarProps> = ({ userId, userRole, onOpenRegistrationModal }) => {
  return (
    <div className="sidebar">
      <NavLink
        to={`/projects/${userId}/${userRole}`}
        className={({ isActive }) => (isActive ? 'active' : '')}
      >
        Projects
      </NavLink>
      
      {userRole === 'Admin' && (
        <button onClick={onOpenRegistrationModal} className="sidebar-button">
          User Registration
        </button>
      )}
    </div>
  );
};

export default Sidebar;
