/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, useNavigate } from 'react-router-dom';
import loginimage from '../images/loginimage.png';
import { AuthenticateUserRequest, AuthenticateUserResponse } from '../../models';
import { useStores } from '../../stores/use-stores';
import '../RegistrySection.scss';
import { ModalDefault } from '../sharedComponents/ModalDefault';
import UserRegistrationModal from './UserRegistrationModal';

const UserLoginPageImpl: React.FC = () => {
  const { apiStore, authStore } = useStores();
  const navigate = useNavigate();
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [loginDenied, setLoginDenied] = useState<boolean>(false);

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const onSignInButtonClick = async () => {
    const requestBody: AuthenticateUserRequest = {
      username: username,
      password: password,
    };
    try {
      const response: AuthenticateUserResponse = await apiStore.authenticateUser(requestBody);
      console.log("AuthenticateUserResponse:", response);
  
      const { log_entry } = response;
      if (log_entry && log_entry.id) {
        authStore.setAuthInfo(response);
        navigate(`/projects/${log_entry.id}/${log_entry.role}`); // Redirect to /projects after successful login
        console.log(`Navigating to /projects/${log_entry.id}`);
      } else {
        console.error("log_entry.id is missing or undefined.");
      }
    } catch (error) {
      console.error("Authentication error:", error);
      let errorMessage = 'Authentication error:';
  
      if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      } else {
        errorMessage += `: ${JSON.stringify(error)}`;
      }
      alert(errorMessage)
      authStore.clearAuthError();
      setLoginDenied(true);
    }
  };
  
  return authStore.isAuthenticated ? (
    <Navigate to={`/projects/${authStore.getAuthenticatedUserId}/${authStore.getAuthenticatedUserRole}`} />
  ) : (
    <div className="registry-section" style={{ display: 'flex', width: '100%', padding: '0px' }}>
      <div style={{ width: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div
          style={{
            width: '494px',
            height: '569px',
            padding: '90px 24px 0px 24px',
            borderStyle: 'outset',
            borderTop: 'none',
            borderRadius: '0px 0px 8px 8px',            
          }}
        >
          <div style={{ margin: 'auto auto 28px auto' }}>
            <h1 style={{ margin: '0px' }}>Sign In</h1>
            <p style={{ fontSize: '18px' }}>to RIT Font Builder</p>
          </div>
          <div className="registry-inner-layout">
            <div className="registry-name-form">
              <div className="form-control">
                <label htmlFor="username">Login ID</label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  placeholder="example@my.com"
                  value={username}
                  onChange={onUsernameChange}
                />
              </div>
              <div className="form-control">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Enter your password"
                  value={password}
                  onChange={onPasswordChange}
                />
              </div>
            </div>
          </div>
          {loginDenied && <p style={{ color: 'red' }}>Invalid user credentials</p>}
          <button className="primary-btn" style={{ marginTop: '44px', marginBottom: '24px' }} onClick={onSignInButtonClick}>
            Sign In
          </button>       
        </div>
      </div>
      <div style={{ width: '50%', minHeight: '70vh' }}>
        <img src={loginimage} alt="rit-font-builder" style={{ objectFit: 'fill', width: '70%', height: 'auto' }} />
      </div>
    </div>
  );
};

export const UserLoginPage = observer(UserLoginPageImpl);
