/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { action, computed, makeObservable, observable } from 'mobx';
import { UserRole } from '../../models';
import { AuthenticateUserResponse } from '../../models/authenticate-user-response';

export class AuthStore {
  private readonly userKey = 'currentUser';
  private readonly tokenKey = 'access_token'; 
  @observable public authInfo?: AuthenticateUserResponse;
  @observable public authError?: {message: string}


  public updateAuthInfoFromLocalStorage() {
    if(!this.authInfo) {
      const authUser = localStorage.getItem(this.userKey);
      if(authUser && authUser !== 'undefined') {
        this.authInfo = JSON.parse(authUser);
      }
    }
  }

  @computed
  public get userInitials(): string {
    let initials = '';
    if(this.authInfo) {
      initials = this.authInfo.log_entry.username.substring(0, 1);
    }
    return initials.toUpperCase();
  }

  constructor() {
    this.updateAuthInfoFromLocalStorage();
    makeObservable(this);
  }

  @action
  setAuthInfo(authResponse: AuthenticateUserResponse) {
    this.clearAuthError();
    this.authInfo = authResponse;
    localStorage.setItem(this.userKey, JSON.stringify(this.authInfo));
    localStorage.setItem(this.tokenKey, authResponse.access_token);
  }

  @action 
  removeAuthInfo() {
    localStorage.removeItem(this.userKey);
    localStorage.removeItem(this.tokenKey);
    this.authInfo = undefined;
  }

  @action
  setAuthError(message: string) {
    console.log('setAuthError : ' + message)
    this.authError = {message: message};
    this.removeAuthInfo();
  }

  @action
  clearAuthError() {
    console.log('cleaning auth Error')
    this.authError = undefined;
  }

  @computed 
  public get isAdmin(): boolean {
    return this.authInfo?.log_entry.role.includes(UserRole.ADMIN) ?? false;
  }

  isAuthorized(allowedRoles: UserRole[]) {
    let isAuthorized = false;
    if(this.authInfo) {
      for(let allowedRole of allowedRoles) {
        if(this.authInfo.log_entry.role.includes(allowedRole)) {
          isAuthorized = true;
          break;
        }
      }
    }
    return isAuthorized;
  }

  get isAuthError(): boolean {
    const isError = this.authError ? true: false
    console.log('isAuthError: ' + isError);
    console.log(this.authError)
    return isError;
  }

  @computed get isAuthenticated(): boolean {
    const userInfo = this.authInfo;
    console.log(userInfo);
    return this.authInfo? true: false;
  }

  @computed get getAuthenticatedUsername() {
    return this.authInfo?.log_entry.username;
  }

  @computed get getAuthenticatedUserId() {
    return this.authInfo?.log_entry.id;
  }

  @computed get getAuthenticatedUserRole() {
    return this.authInfo?.log_entry.role;
  }
}
