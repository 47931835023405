/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Form, Row, Col } from 'react-bootstrap';

import { useStores } from '../../stores/use-stores';
import { fontStore } from '../../stores/data/fontStore';
import ini from 'ini'; // Import the ini package
import './UploadFontMetadata.css';
import Sidebar from '../sharedComponents/SideBar';
import { FontMetadata } from './FontMetaData';

interface UploadFontMetadataPageProps {
  userId: number;
  projectId: number;
  closeModal: () => void;
}

// Define a type for the entry data
interface EntryData {
  foundry: string;
  family?: string;
  weightclass?: string;
  version?: string;
  ascent?: number;
  descent?: number;
  designer?: string;
  copyright?: string;
  license?: string;
}

// Define a type for sections
interface Sections {
  font: EntryData;
  source: { [key: string]: string | number | boolean }; // Source section
}

const UploadFontMetadataPage: React.FC<UploadFontMetadataPageProps> = observer(({ userId, projectId, closeModal }) => {
  const { apiStore } = useStores();
  const [sections, setSections] = useState<Sections>({
    font: {
      foundry: "RIT", // Keeping foundry for clarity, though it's constant
      copyright: "Rachana Institute of Typography",
      license: "OFL 1.1",
    },
    source: { 
      scaleglyphs: 'false', // Default value as string
      include_foundry: 'false',
    },
  });

  useEffect(() => {
    const fetchFontEntry = async () => {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
            throw new Error("You are not authorized");
        }
        
        const { files } = await apiStore.getIniFiles(userId, projectId, accessToken);
        if (files.length > 0) {
          const content = files[0].content; // Assume only one file
          const parsedContent = ini.parse(content); // Parse the INI content

          // Set the sections from the INI file
          setSections({
            font: {
              foundry: parsedContent['font']?.foundry || '',
              family: parsedContent['font']?.family || '',
              weightclass: parsedContent['font']?.weightclass || '',
              version: parsedContent['font']?.version || '',
              ascent: parsedContent['font']?.ascent ? Number(parsedContent['font'].ascent) : 640,
              descent: parsedContent['font']?.descent ? Number(parsedContent['font'].descent) : 360,
              designer: parsedContent['font']?.designer || '',
              copyright: parsedContent['font']?.copyright || '',
              license: parsedContent['font']?.license || '',
            },
            source: {
              scaleglyphs: parsedContent['source']?.scaleglyphs ? 'true' : 'false', // Ensure string conversion
              include_foundry: parsedContent['source']?.include_foundry ? 'true' : 'false', // Ensure string conversion
            },
          });
        }
      } catch (error) {
        console.error('Error fetching font metadata:', error);
      }
    };

    fetchFontEntry();
  }, [userId, projectId, apiStore]);

  const handleChange = (field: keyof EntryData, value: any) => {
    setSections(prevSections => ({
      ...prevSections,
      font: {
        ...prevSections.font,
        [field]: typeof field === 'boolean' ? (value ? 'true' : 'false') : value,
      },
    }));
  };

  const handleSourceChange = (field: keyof Sections['source'], value: boolean) => {
    setSections(prevSections => ({
      ...prevSections,
      source: {
        ...prevSections.source,
        [field]: value ? 'true' : 'false', 
      },
    }));
  };

  useEffect(() => {
    const ascent = sections.font.ascent || 640;
    const descent  = sections.font.descent || 360;
    if (ascent + descent !== 1000) {
      const newDescent = 1000 - ascent;
  
      // Update only if the new descent is different from the current descent
      if (newDescent !== sections.font.descent) {
        setSections(prevSections => ({
          ...prevSections,
          font: {
            ...prevSections.font,
            descent: Math.max(newDescent, 0), // Prevent descent from going negative
          },
        }));
      }
    }
  }, [sections.font.ascent]);
  
  
  
  const handleSave = async () => {
    // Update the fontStore with the current entryData
    fontStore.setFoundry(sections.font.foundry);
    fontStore.setFontFamily(sections.font.family || '');
    fontStore.setFontVersion(sections.font.version || '');
    fontStore.setFontWeight(sections.font.weightclass || 'regular');
    fontStore.setAscent(sections.font.ascent || 640);
    fontStore.setDescent(sections.font.descent || 360);
    fontStore.setDesigner(sections.font.designer || '');
    fontStore.setCopyright(sections.font.copyright || '');
    fontStore.setLicense(sections.font.license || '');
    fontStore.setScaleglyphs(sections.source.scaleglyphs === 'true');
    fontStore.setIncludeFoundry(sections.source.include_foundry === 'true');

    const fontMetadata: FontMetadata = {
      foundry: fontStore.foundry,
      family: fontStore.fontFamily,
      version: fontStore.fontVersion,
      weightclass: fontStore.fontWeight,
      ascent: fontStore.ascent,
      descent: fontStore.descent,
      designer: fontStore.designer,
      copyright: fontStore.copyright,
      license: fontStore.license,
      scaleglyphs: fontStore.scaleglyphs,
      include_foundry: fontStore.includeFoundry,
      glyphdir: '',
    };

    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        throw new Error("You are not authorized");
      }
    
      console.log("Metadata to update: ", fontMetadata)
      await apiStore.uploadFontMetadata(userId, projectId, fontMetadata, accessToken);
      closeModal(); //alert('Font metadata saved successfully!');
    } catch (error) {
      console.error('Error saving font metadata:', error);
      let errorMessage = 'Error saving font metadata.';

      if (error instanceof Error) {
        errorMessage += `: ${error.message}`;
      } else {
        errorMessage += `: ${JSON.stringify(error)}`;
      }

      alert(errorMessage);
      }
  };

  return (
    <Form>
        <h1>Font Metadata</h1>
        <div className="subheader">View and update font details</div>

        <Button className="close-button" onClick={closeModal}>✖</Button> {/* Close button as "X" */}

        {/* Input Fields for Entry */}
        <Form className="entry-form">
          <h2>Basic</h2>
            <Form.Group as={Row} className="mb-1" controlId="formFoundry">
              <Form.Label column sm={3}>Foundry</Form.Label>
              <Col sm={9}>
                <Form.Control type="text" value={sections.font.foundry || 'RIT'} disabled/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1" controlId="formFamily">
              <Form.Label column sm={3}>Family</Form.Label>
              <Col sm={9}>
                <Form.Control
                type="text"
                value={sections.font.family || ''}
                onChange={(e) => handleChange('family', e.target.value)}
                />
              </Col>
            </Form.Group>

            {/* Font Weight - Changed to select */}
            <Form.Group as={Row} className="mb-1" controlId="formWeightClass">
              <Form.Label column sm={3}>Weight</Form.Label>
              <Col sm={9}>
                <Form.Select
                  value={sections.font.weightclass || ''}
                  onChange={(e) => handleChange('weightclass', e.target.value)}
                >
                  <option value="thin">Thin</option>
                  <option value="extralight">Extra Light</option>
                  <option value="light">Light</option>
                  <option value="regular">Regular</option>
                  <option value="medium">Medium</option>
                  <option value="semibold">Semibold</option>
                  <option value="bold">Bold</option>
                  <option value="extrabold">Extra Bold</option>
                  <option value="black">Black</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1" controlId="formVersion">
              <Form.Label column sm={3}>Version</Form.Label>
              <Col sm={9}>
                <Form.Control
                type="text"
                value={sections.font.version || ''}
                onChange={(e) => handleChange('version', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1" controlId="formAscent">
              <Form.Label column sm={3}>Ascent</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="number"
                  max="1000"
                  value={sections.font.ascent || 640}  // Default to 640 if undefined
                  onChange={(e) => handleChange('ascent', Number(e.target.value))}
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1" controlId="formDescent">
              <Form.Label column sm={3}>Descent</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="number"
                  max="1000"
                  value={sections.font.descent || 360}  // Default to 360 if undefined
                  onChange={(e) => handleChange('descent', Number(e.target.value))}
                  disabled
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row} className="mb-1" controlId="formDesigner">
              <Form.Label column sm={3}>Designer</Form.Label>
              <Col sm={9}>
                <Form.Control
                type="text"
                value={sections.font.designer || ''}
                onChange={(e) => handleChange('designer', e.target.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-1" controlId="formCopyright">
              <Form.Label column sm={3}>Copyright</Form.Label>
              <Col sm={9}>
                <Form.Control
                type="text"
                value={sections.font.copyright || ['Rachana Institute of Typography', sections.font.designer].join(', ')}
                onChange={(e) => handleChange('copyright', e.target.value)}
                />
              </Col>
            </Form.Group>

            {/* License - disabled */}
            <Form.Group as={Row} className="mb-1" controlId="formLicense">
              <Form.Label column sm={3}>License</Form.Label>
              <Col sm={9}>
                <Form.Control
                  type="text"
                  value={sections.font.license || 'OFL 1.1'} disabled>
                </Form.Control>
              </Col>
            </Form.Group>

            <h2>Advanced</h2>
           <Form.Group as={Row} className="mb-1" controlId="formSourceScaleglyphs">
            <Form.Label column sm={7}>Scale Glyphs</Form.Label>
            <Col sm={5}>
              <Form.Check
                type="switch"
                checked={sections.source.scaleglyphs === 'true'}
                onChange={(e) => handleSourceChange('scaleglyphs', e.target.checked)}
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} className="mb-1" controlId="formSourceIncludeFoundry">
            <Form.Label column sm={7}>Include Foundry in font name</Form.Label>
            <Col sm={5}>
              <Form.Check
                type="switch"
                checked={sections.source.include_foundry === 'true'}
                onChange={(e) => handleSourceChange('include_foundry', e.target.checked)}
              />
            </Col>
           </Form.Group>

          <Button onClick={handleSave}>Save</Button>
        </Form>
    </Form>
  );
});

export default UploadFontMetadataPage;
