import React from 'react';
import { Box, Button, Page, PageContent, Text, Heading } from 'grommet';
import { Accordion } from 'react-bootstrap';
import './SvgFilesPage.scss'; // Import the SCSS file
import { fileNameMappings } from '../../utils/fileNameMapping';
import { SvgFile } from './ProjectFile';

interface SvgFilesPageProps {
  svgFiles: SvgFile;
  closeModal: () => void;
}

const SvgFilesPage: React.FC<SvgFilesPageProps> = ({ svgFiles, closeModal }) => {
  const categorizeFiles = () => {
    const categorizedFiles: { [key: string]: SvgFile } = {};

    Object.entries(fileNameMappings).forEach(([category, catkeys]) => {
      const glyphmap = catkeys["glyph_map"];
      if (glyphmap && typeof glyphmap === 'object') {
        Object.entries(glyphmap).forEach(([letter, glyphname]) => {
          if (Object.keys(svgFiles).includes(glyphname)) {
            if (!categorizedFiles[category]) {
              categorizedFiles[category] = {};
            }
            categorizedFiles[category][glyphname] = svgFiles[glyphname];
          }
        });
      }
    });

    return categorizedFiles;
  };

  const categorizedFiles = categorizeFiles();

  const renderPreviews = (files: SvgFile) => (
    <Box direction="row" wrap gap="small" pad="small">
      {Object.entries(files).map(([glyphname, svgContent]) => (
        <Box
          key={glyphname}
          width="small"
          height="small"
          align="center"
          justify="center"
          background="light-1"
          pad="small"
          overflow="hidden"
          border={{ color: 'light-3', size: 'small' }}
          round="small"
        >
          {svgContent ? (
            <img
              src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgContent)}`}
              alt={glyphname}
              className="uploaded-svg"
              style={{ maxWidth: '100%', maxHeight: '100%' }} // Ensure the SVG fits within the box
            />
          ) : (
            <Text>No preview available</Text>
          )}
        </Box>
      ))}
    </Box>
  );

  return (
    <div className="modal-overlay white-background">
      <div className="modal-content">
        <Page kind="full">
          <PageContent height="100%">
            <Heading level="2" margin="medium">SVG Files</Heading>
            <Box height="100%" overflow="auto">
              {!categorizedFiles ? (
                <Box margin="small" align="center" height="100%" direction="row">
                  <Box alignContent="center" align="center" width="100%">
                    <Text color="text-weak" size="xxlarge">
                      No SVG files available
                    </Text>
                  </Box>
                </Box>
              ) : (
                <Accordion defaultActiveKey={Object.keys(categorizedFiles)[0]}>
                  {Object.keys(categorizedFiles).map((category) => (
                    <Accordion.Item eventKey={category} key={category}>
                      <Accordion.Header>
                        {fileNameMappings[category].description.en}
                      </Accordion.Header>
                      <Accordion.Body>
                        { renderPreviews(categorizedFiles[category])}
                      </Accordion.Body>
                    </Accordion.Item>
                  ))}
                </Accordion>
              )}
            </Box>
            <Box
              align="center"
              pad="small"
              direction="row"
              justify="end"
              className="close-button-container"
            >
              <Button label="Close" onClick={closeModal} />
            </Box>
          </PageContent>
        </Page>
      </div>
    </div>
  );
};

export default SvgFilesPage;
