/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import { action, computed, makeObservable, observable } from 'mobx';

export class FontStore {
  [x: string]: any;
  @observable public foundry: string = '';
  @observable public includeFoundry: boolean = true;
  @observable public fontFamily: string = '';
  @observable public fontWeight: string = 'regular';
  @observable public fontVersion: string = '';
  @observable public copyright: string = '';
  @observable public designer: string = '';
  @observable public ascent: number = 640;
  @observable public descent: number = 360;
  @observable public license: string = '';
  @observable public scaleglyphs: boolean = true;

  constructor() {
    makeObservable(this);
  }

  @action
  setFoundry(foundry: string) {
    this.foundry = foundry;
  }

  @action
  setIncludeFoundry(include_foundry: boolean) {
    this.includeFoundry = include_foundry;
  }

  @action
  setFontFamily(family: string) {
    this.fontFamily = family;
  }

  @action
  setFontWeight(weight: string) {
    this.fontWeight = weight;
  }

  @action
  setFontVersion(version: string) {
    this.fontVersion = version;
  }

  @action
  setCopyright(copyright: string) {
    this.copyright = copyright;
  }

  @action
  setDesigner(designer: string) {
    this.designer = designer;
  }

  @action
  setAscent(ascent: number) {
    this.ascent = ascent;
  }

  @action
  setDescent(descent: number) {
    this.descent = descent;
  }

  @action
  setLicense(license: string) {
    this.license = license;
  }

  @action
  setScaleglyphs(scale: boolean) {
    this.scaleglyphs = scale;
  }

  @computed
  get fontMetadata() {
    return {
      foundry: this.foundry,
      family: this.fontFamily,
      weightclass: this.fontWeight,
      version: this.fontVersion,
      ascent: this.ascent,
      descent: this.descent,
      copyright: this.copyright,
      license: this.license,
      scaleglyphs: this.scaleglyphs,
      include_foundry: this.includeFoundry,
    };
  }
}

export const fontStore = new FontStore();
