/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

/* eslint-disable no-else-return */
import { IFetchContext } from './fetch-context';

export interface ErrorResponse {
  statusCode: number;
  message: string;
}

export const responseErrorHandler = async <T>(
  fetchContext: IFetchContext,
  response: Response,
  operationTitle: string,
  jsonContentExpected = true,
  statusesToIgnoreError: number[] = [],
  badRequestInJsonExpected = false,
): Promise<T> => {
  const { status, statusText } = response;
  const success = (status >= 200 && status < 300);
  const errorIgnored = statusesToIgnoreError.includes(status);

  if (status === 204) return Promise.resolve<T>(undefined!);

  const errorMessage = `(HTTP ${status}${statusText ? ` ${statusText}` : statusText}) Failed to ${operationTitle}.`;
  if (!success && !errorIgnored) fetchContext.errorStore.reportError(errorMessage);

  if (success && jsonContentExpected) {
    return Promise.resolve<T>(response.json());
  } else if (success && !jsonContentExpected) {
    return Promise.resolve<T>(response.text() as unknown as T);
  } else if (!success && !jsonContentExpected) {
    // Changing this to reject as if the  operation is not success,
    // and json content not there, need to reject. 
    return Promise.reject<T>(response.text() as unknown as T);
  } else if (!success && status === 400 && badRequestInJsonExpected) {
    return Promise.reject(await response.json());
  } else if (!success && status === 401) {
    const authResponse = await response.json()
    const message = authResponse.errorMessage? authResponse.errorMessage : authResponse.message;
    fetchContext.authStore.setAuthError(message);
    return Promise.reject<T>(authResponse); // let the call succeed and redirect in auth store
  } else if (!success && errorIgnored) {
    return Promise.resolve<T>(undefined!);
  } else {
    return Promise.reject(await response.json());
  }
};
