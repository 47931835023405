/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import { Project } from '../models';
import { DataCollection } from '../models/data-collection';
import { IFetchContext } from './fetch-context';
import { responseErrorHandler } from './response-error-handler';

export const getProjectById: (
  fetchContext: IFetchContext,
  userId: Number,
  projectId: Number
) => Promise<Project> = (fetchContext, userId, projectId) => {
  // Retrieve the access token from local storage
  const token = localStorage.getItem('access_token');

  return fetch(`${fetchContext.baseUrl}/${userId}/get-project/${projectId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`, // Add the access token here
    },
  })
  .then((response) => responseErrorHandler<Project>(
    fetchContext,
    response,
    'post authenticateUser',
    true,
    [],
    true
  ));
};
