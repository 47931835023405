/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Project } from '../models';
import { IFetchContext } from './fetch-context'
import { responseErrorHandler } from './response-error-handler'

export const createProject: (
  fetchContext: IFetchContext,
  project: Project,
  accessToken: String
) => Promise<void> = (fetchContext, project, accessToken) => fetch(`${fetchContext.baseUrl}/create-project`,
  {
    method: 'POST',
    body: JSON.stringify(project),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
  })
  .then((response) => responseErrorHandler<void>(fetchContext, response, 'post authenticateUser', true, [], true));
