/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Box, Button, Notification, Text } from 'grommet';
import { ModalDefault } from './ModalDefault';

export interface ErrorModalProps {
  isModalOpen: boolean;
  customClassName?: string;
  showWarning?: boolean;
  warningText?: string;
  headerText: string;
  modalText?: string;
  button1Text?: string;
  button1Handler: () => void;
  resource?: any;
  children?: React.ReactNode;
}

export const ErrorModal: React.FC<ErrorModalProps> = (props) => {
  return (
    <ModalDefault headerText={props.headerText} isModalOpen={props.isModalOpen}>
      {props.showWarning && <Notification status="critical" message={props.warningText} />}
      <Box margin="none" pad="none" height={{ max: "50vh" }} style={{ overflowY: "auto" }}>
        <Text margin="small">{props.modalText}</Text>
        <>{props.children}</>
      </Box>
      <Box direction="row" gap="small" style={{ justifyContent: 'right' }} margin={{ top: 'medium' }}>
        <Button primary label={props.button1Text ?? 'OK'} type="submit" onClick={props.button1Handler} />
      </Box>
    </ModalDefault>
  );
};
