
/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { makeObservable, observable } from 'mobx';

export class ApiEndpointCallStatesStore {
  constructor() {
    makeObservable(this);
  }
  // Users
  @observable createUserInFlight = false;
  @observable authenticateUserInFlight = false;
  @observable createProjectsInFlight = false;
  @observable getAllProjectsInFlight = false;
  @observable getAllUsersProjectsInFlight = false;
  @observable getProjectByIdInFlight = false;
  @observable deleteProjectsInFlight = false;
  @observable uploadFilesInFlight = false;
  @observable uploadFontMetaDataInFlight = false;
  @observable getSvgFilesInFlight = false;
  @observable getIniFilesInFlight = false;
}