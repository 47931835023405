/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { AuthStore } from '../stores/data';
import { ErrorStore } from '../stores/data/error-store';

export interface IFetchContext {
  baseUrl: string;
  errorStore: ErrorStore;
  authStore: AuthStore;
}

export const fetchContext: IFetchContext = {
  //baseUrl: 'http://localhost:5557/rit-font-builder/v1', 
  //for deployment to server, to avoid CORS error
  //ensure web server to proxy '/rit-font-builder/v1' to 'localhost:5557/rit-font-builder/v1'
  baseUrl: '/rit-font-builder/v1',
  errorStore: new ErrorStore(),
  authStore: new AuthStore(),
};
