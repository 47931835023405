/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Box, Button, Notification, Text } from 'grommet';
import { ModalDefault } from './ModalDefault';

interface ConfirmationProps {
  isModalOpen: boolean;
  customClassName?: string;
  showWarning?: boolean;
  warningText?: string;
  headerText: string;
  modalText: string;
  button1Text?: string;
  button2Text?: string;
  onCancel: () => void;
  onConfirm: (hostId: number) => void;
  resource?: any;
  children?: React.ReactNode;
}

export const ConfirmationModal: React.FC<ConfirmationProps> = (props) => {
  const defaults = { showWarning: true, button1Text: 'Cancel', button2Text: 'Delete', warningText: 'This action cannot be undone.' };
  const updatedProps = { ...defaults, ...props };

  return (
    <ModalDefault headerText={updatedProps.headerText} isModalOpen={updatedProps.isModalOpen}>
      {updatedProps.showWarning && <Notification status="critical" message={updatedProps.warningText} />}
      <Box margin="none" pad="none">
        <Text margin="small">{updatedProps.modalText}</Text>
        <>{updatedProps.children}</>
      </Box>
      <Box direction="row" gap="small" style={{ justifyContent: 'right' }} margin={{ top: 'medium' }}>
        <Button label={updatedProps.button1Text} onClick={updatedProps.onCancel} />
        <Button
          primary
          label={updatedProps.button2Text}
          type="submit"
          onClick={() => {
            props.onConfirm(updatedProps.resource);
          }}
        />
      </Box>
    </ModalDefault>
  );
};
