// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
.user-registration-form .form-control {
  margin-bottom: 16px;
}
.user-registration-form .form-control label {
  display: block;
  margin-bottom: 8px;
}
.user-registration-form .form-control input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
.user-registration-form .primary-btn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.user-registration-form .primary-btn:hover {
  background-color: #0056b3;
}

.close-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px;
  border-radius: 50%;
  transition: background-color 0.3s ease;
}
.close-button:hover {
  background-color: #f2f2f2;
}`, "",{"version":3,"sources":["webpack://./src/pages/UserLogin/UserRegistrationModal.scss"],"names":[],"mappings":"AAAA;;;;;2DAAA;AAQI;EACE,mBAAA;AADN;AAGM;EACE,cAAA;EACA,kBAAA;AADR;AAIM;EACE,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sBAAA;AAFR;AAMI;EACE,yBAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,eAAA;EACA,sCAAA;AAJN;AAMM;EACE,yBAAA;AAJR;;AASE;EACE,gBAAA;EACA,YAAA;EACA,iBAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,sCAAA;AANJ;AAQI;EACE,yBAAA;AANN","sourcesContent":["/* ********************************************************\n# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,\n# Author: Divya C <cdivyanambiar@gmail.com>\n# Author: Rajeesh KV <rajeesh@rachana.org.in>\n# This file is licensed under AGPLv3\n********************************************************* */\n\n.user-registration-form {\n    .form-control {\n      margin-bottom: 16px;\n      \n      label {\n        display: block;\n        margin-bottom: 8px;\n      }\n      \n      input {\n        width: 100%;\n        padding: 8px;\n        border-radius: 4px;\n        border: 1px solid #ccc;\n      }\n    }\n  \n    .primary-btn {\n      background-color: #007bff; // Adjust the background color as needed\n      color: #fff; // Text color\n      border: none;\n      padding: 10px 20px;\n      font-size: 16px;\n      border-radius: 4px;\n      cursor: pointer;\n      transition: background-color 0.3s ease;\n    \n      &:hover {\n        background-color: #0056b3; // Adjust the hover background color as needed\n      }\n    }\n  }\n  \n  .close-button {\n    background: none;\n    border: none;\n    font-size: 1.5rem;\n    cursor: pointer;\n    color: #333;\n    position: absolute;\n    top: 10px;\n    right: 10px;\n    padding: 5px;\n    border-radius: 50%;\n    transition: background-color 0.3s ease;\n  \n    &:hover {\n      background-color: #f2f2f2;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
