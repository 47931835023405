import { Notification } from 'grommet';
import { observer } from 'mobx-react-lite';
import { Navigate, useLocation } from 'react-router-dom';
import { Footer, Header } from './pages/sharedComponents';
import { useStores } from './stores/use-stores';
import Views from './views';

function App() {
  const { authStore } = useStores();
  const location = useLocation();

  return (
    <div className="App">
      <Header />
      {authStore.authError && (
        <>
          <Navigate to="/login" replace state={{ from: location.pathname }} />
          <Notification
            toast={{ autoClose: false, position: 'top' }}
            status="critical"
            title="Authentication Failure"
            onClose={() => {
              authStore.clearAuthError();
            }}
            message={authStore.authError?.message}
          />
        </>
      )}
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Views />
      </div>
      <Footer />
    </div>
  );
}

export default observer(App);
