import { AuthenticateUserRequest } from '../models';
import { IFetchContext } from './fetch-context';
import { responseErrorHandler } from './response-error-handler';

interface User {
  id: number;
  username: string;
  role: string | null;
}

interface UserResponse {
  access_token: string;
  user: User;
}

export const createUser = async (
  fetchContext: IFetchContext,
  user: AuthenticateUserRequest
): Promise<UserResponse> => {
  try {
    const response = await fetch(`${fetchContext.baseUrl}/create-login-user`, {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // Check if the response is OK (status in the range 200-299)
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data: UserResponse = await response.json();

    // Check if access token is returned and store it
    if (data.access_token) {
      localStorage.setItem('access_token', data.access_token);
    }

    return data;
  } catch (error) {
    console.error('Failed to create user:', error);
  
    // Error message based on type
    const errorMessage = error instanceof Error ? error.message : 'Unknown error occurred';
  
    // Call responseErrorHandler to handle the error gracefully
    responseErrorHandler<void>(
      fetchContext,
      new Response(JSON.stringify({ error: errorMessage })),
      'post authenticateUser',
      true,
      [],
      true
    );
  
    // Re-throw only if it's an expected error type
    throw new Error(errorMessage);
  }
};
