/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { ProjectFile } from '../pages/Projects/ProjectFile';
import { IFetchContext } from './fetch-context';
import { responseErrorHandler } from './response-error-handler';

export const getIniFiles: (
  fetchContext: IFetchContext,
  userId: number,
  projectId: number,
  accessToken: String
) => Promise<{
    [x: string]: any; files: ProjectFile[] 
}>
= (fetchContext, userId, projectId, accessToken) => fetch(`${fetchContext.baseUrl}/${userId}/view_ini_files/${projectId}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
  })
  .then(response => responseErrorHandler<{ files: ProjectFile[] }>(fetchContext, response, 'getIniFiles', true, [], true));
