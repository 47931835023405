/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */


import { action, computed, makeObservable, observable } from 'mobx';

export class ErrorStore {
  constructor() {
    makeObservable(this);
  }

  private readonly _validationErrorHeader: string = '[Validation Error]';

  @observable private _errors: Set<string> = new Set<string>();

  @observable private _validationErrors: Set<string> = new Set<string>();

  @computed get errors(): string[] {
    const errors = Array.from(this._errors.values());
    const validationErrors = Array.from(this._validationErrors.values());
    return errors.concat(validationErrors);
  }

  @action reportError(errorMessage: string) {
    this._errors.clear();
    this._errors.add(errorMessage);
  }

  @action reportValidationError(errorMessage: string, autoHideDurationMs?: number) {
    const errorMessageWithHeader = `${this._validationErrorHeader} — ${errorMessage}`;
    this._validationErrors.add(errorMessageWithHeader);

    autoHideDurationMs &&
      setTimeout(() => {
        this._validationErrors.delete(errorMessageWithHeader);
      }, autoHideDurationMs);
  }

  @action clearValidationError() {
    this._validationErrors.clear();
  }
}
