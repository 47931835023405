/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import React from 'react';

import { AppStoresContext } from './app/app-stores-context';

export const useStores = () => {
  const stores = React.useContext(AppStoresContext);

  if (!stores) {
    throw new Error('useStores must be used within an AppStoresProvider.');
  }

  return stores;
};