/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { ApiEndpointCallStatesStore } from '.'
import { AuthenticateUserRequest, Project } from '../models';
import { FontMetadata } from '../pages/Projects/FontMetaData';
import { authenticateUser } from './authenticate-user';
import { createProject } from './createProject';
import { createUser } from './createUser';
import { deleteProject } from './delete-project';
import { fetchContext } from './fetch-context';
import { getAllProjects } from './getAllProjects';
import { getAllUsersProjects } from './getAllUsersProjects';
import { getProjectById } from './getProjectsById';
import { promiseWithObservableState } from './promise-with-observable-state';
import { uploadFileForProjects } from './uploadFileForProject';
import { uploadFontMetadata } from './uploadFontMetada';
import { getIniFiles } from './viewAlliniForProjects';
import { getSvgFiles } from './viewAllSvgForProjects';

export const createApiStore = (    
    apiEndpointCallStates: ApiEndpointCallStatesStore,
  ) => {
    const apiEndpointHandlers = {
          // user
      createUser: (body: AuthenticateUserRequest) =>
        promiseWithObservableState(
          createUser(fetchContext, body),
          (isInFlight) => {
            apiEndpointCallStates.createUserInFlight = isInFlight;
          }
        ),
      authenticateUser: (body: AuthenticateUserRequest) =>
        promiseWithObservableState(
          authenticateUser(fetchContext, body),
          (isInFlight) => {
            apiEndpointCallStates.authenticateUserInFlight = isInFlight;
          }
        ),

      // Projects
      createProject: (project: Project, accessToken: String) => promiseWithObservableState(
        createProject(fetchContext, project, accessToken),
        (isInFlight) => {
          apiEndpointCallStates.createProjectsInFlight = isInFlight;
        },
      ),

      getAllProjects: (userId: number, accessToken: String) => promiseWithObservableState(
      getAllProjects(fetchContext, userId, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.getAllProjectsInFlight = isInFlight;
      },
     ),

     getAllUsersProjects: (userId: number, accessToken: String) => promiseWithObservableState(
      getAllUsersProjects(fetchContext, userId, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.getAllUsersProjectsInFlight = isInFlight;
      },
     ),

     getProjectById: (userId: number, projectId: number) => promiseWithObservableState(
      getProjectById(fetchContext, userId, projectId),
      (isInFlight) => {
        apiEndpointCallStates.getProjectByIdInFlight = isInFlight;
      },
     ),

     deleteProject: (userId: number, projectId: number, accessToken: String) => promiseWithObservableState(
      deleteProject(fetchContext, userId, projectId, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.deleteProjectsInFlight = isInFlight;
      },
     ),

    uploadFileForProjects: (request: FormData, accessToken: String) => promiseWithObservableState(
      uploadFileForProjects(fetchContext, request, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.uploadFilesInFlight = isInFlight;
      },
    ),  

    uploadFontMetadata: (userId: number, projectId: number, request: FontMetadata, accessToken: String) => promiseWithObservableState(
      uploadFontMetadata(fetchContext, userId, projectId, request, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.uploadFontMetaDataInFlight = isInFlight;
      },
    ),  

    getSvgFiles: (userId: number, projectId: number, accessToken: String) => promiseWithObservableState(
      getSvgFiles(fetchContext, userId, projectId, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.getSvgFilesInFlight = isInFlight;
      },
    ),

    getIniFiles: (userId: number, projectId: number, accessToken: String) => promiseWithObservableState(
      getIniFiles(fetchContext, userId, projectId, accessToken),
      (isInFlight) => {
        apiEndpointCallStates.getIniFilesInFlight = isInFlight;
      },
    ),  

    };

    

      return {
        ...apiEndpointHandlers,
      };
};
