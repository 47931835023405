
/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import React, { useEffect, useState } from 'react';
import { Box, Button, Page, PageContent, Text } from 'grommet';
import { DocumentUpload, ObjectGroup, Trash, View } from 'grommet-icons';
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import { ApiError, Project } from '../../models';
import { useStores } from '../../stores/use-stores';
import './ProjectPage.scss';
import { ErrorModal, FontPageHeader, PageSpinner } from '../sharedComponents';
import { ConfirmationModal } from '../sharedComponents/ConfirmationModal';
import SvgFilesPage from './SvgFilesPage';
import FileUploadComponent from './FileUploadComponent';
import { ProjectFile, SvgFile } from './ProjectFile';
import CreateProjectModal from './CreateProjectModal';
import Sidebar from '../sharedComponents/SideBar'; // Import the Sidebar component
import UploadGlyphPage from './UploadFontMetadata';
import { FALSE } from 'sass';
import UserRegistrationModal from '../UserLogin/UserRegistrationModal';

interface UserParams extends Record<string, string | 'User'> {
  userId: string;
  userRole: string;
}

const ProjectsListPageImpl: React.FC = () => {
  const { userId: userIdParam, userRole: userRoleParam} =  useParams<UserParams>();
  const userId = userIdParam ? Number(userIdParam) : NaN;
  const userRole = userRoleParam || 'User'; 
  const navigate = useNavigate();
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [errorWarningText, setErrorWarningText] = useState<string>('');
  const [errorHeading, setErrorHeading] = useState<string>('Project Error');
  const [errorMessageBody, setErrorMessageBody] = useState<string>('Project Error');
  const [projectsList, setProjectsList] = useState<Project[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [showDeleteProject, setShowDeleteProject] = useState<boolean>(false);
  const [showLogPanel, setShowLogPanel] = useState<boolean>(false);
  const [showGlyphPanel, setShowGlyphPanel] = useState<boolean>(false);
  const [showSvgFiles, setShowSvgFiles] = useState<boolean>(false);
  const [svgFiles, setSvgFiles] = useState<SvgFile>({});
  const [showCreateProjectModal, setShowCreateProjectModal] = useState<boolean>(false);
  const [isRegistrationModalOpen, setRegistrationModalOpen] = useState(false);
  const onOpenRegistrationModal = () => {
    setRegistrationModalOpen(true); // This opens the modal
  };
  const onRegistrationModalClose = () => {
    setRegistrationModalOpen(false);
  };
  const { apiStore, apiEndpointCallStates } = useStores();

  const handleCreateProject = async (project: Project) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        throw new Error("You are not authorized");
      }
    
      await apiStore.createProject(project, accessToken); // Create the project
      await getProjectsList(); // Refresh the projects list
    } catch (err) {
      const error = err as ApiError;
      setErrorWarningText(`Failed to create project`);
      setErrorMessageBody(error.errorMessage);
      setShowErrorModal(true);
    }
  };

  const getProjectsList = async () => {
    if (!isNaN(userId)) {
      try {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          throw new Error("Access token not found in local storage");
        }
        let response;
        if (userRole === 'Admin') {
          response = await apiStore.getAllUsersProjects(userId, accessToken);
        } else {
          response = await apiStore.getAllProjects(userId, accessToken);
        }
        setProjectsList(response.data);
      } catch (err) {
        const error = err as ApiError;
        setErrorWarningText(`Failed to get project list`);
        setErrorMessageBody(error.errorMessage);
        setShowErrorModal(true);
      }
    }
  };

  const fetchSvgFiles = async (projectId: number) => {
    try {
      const accessToken = localStorage.getItem('access_token');
      if (!accessToken) {
        throw new Error("You are not authorized");
      }
    
      const response = await apiStore.getSvgFiles(userId, projectId, accessToken);
      setSvgFiles(response.files);
      setShowSvgFiles(true);
    } catch (err) {
      const error = err as ApiError;
      setErrorWarningText(`Failed to fetch SVG files`);
      setErrorMessageBody(error.errorMessage);
      setShowErrorModal(true);
    }
  };

  const onProjectListItemClick = (id: number) => {
    navigate(`/projects/${id}`);
  };

  const deleteProjectClick = (project: Project) => {
    setSelectedProject(project);
    setShowDeleteProject(true);
  };

  const deleteProject = async () => {
    setShowDeleteProject(false);
    try {
      if (selectedProject) {
        const accessToken = localStorage.getItem('access_token');
        if (!accessToken) {
          throw new Error("Access token not found in local storage");
        }
        await apiStore.deleteProject(userId, selectedProject.id, accessToken); // Assuming a delete method exists
        await getProjectsList(); // Refresh projects list
      }
    } catch (err) {
      const error = err as ApiError;
      setErrorWarningText(`Failed to delete project`);
      setErrorMessageBody(error.errorMessage);
      setShowErrorModal(true);
    } finally {
      setSelectedProject(null);
    }
  };

  const collectLogClick = (project: Project) => {
    setSelectedProject(project);
    setShowLogPanel(true);
  };
  
  const collectGlyphClick = (project: Project) => {
    setSelectedProject(project);
    setShowGlyphPanel(true);
  };

  const viewSvgFilesClick = (projectId: number) => {
    fetchSvgFiles(projectId);
  };

  useEffect(() => {
    getProjectsList();
  }, [userId]);

  const listHeader = () => (
    <Box pad={{ bottom: 'xsmall', horizontal: 'medium', top: 'large' }} direction="row">
      <Box style={{ width: '35%' }}>
        <Text weight="bold">Font name</Text>
      </Box>
      <Box style={{ width: '50%' }}>
        <Text weight="bold">Font description</Text>
      </Box>
    </Box>
  );

  const projectsListComponent = () => (
    <>
      {projectsList.length > 0 ? (
        projectsList.map((project) => (
          <Box
            key={project.id}
            elevation="medium"
            border={[
              { color: 'border-weak', size: 'xsmall', style: 'solid', side: 'top' },
              { color: 'border-weak', size: 'xsmall', style: 'solid', side: 'bottom' },
              { color: 'border-weak', size: 'xsmall', style: 'solid', side: 'vertical' },
            ]}
            pad={{ vertical: 'medium', horizontal: 'medium' }}
            direction="row"
            style={{ width: '100%', borderRadius: '5px' }}
            align="center"
          >
            <Box style={{ width: '50%' }}>
              <Text className="project-name">{project.projectName}</Text>
            </Box>
            <Box style={{ width: '30%' }}>
              <Text className="project-description">{project.description}</Text>
            </Box>
            <Box align="center" style={{ display: 'flex', width: '50%', textAlign: 'start', flexDirection: 'row' }}>
            {userId === project.userId && (
              <React.Fragment>
              <Box title="Delete Project" style={{ width: '10%' }} align="end" justify="end" alignContent="end">
                <Button icon={<Trash />} onClick={() => deleteProjectClick(project)} />
              </Box>
              <Box title="Upload SVG Files" style={{ width: '10%' }} align="end" justify="end" alignContent="end">
                <Button icon={<DocumentUpload />} onClick={() => collectLogClick(project)} />
              </Box>
              </React.Fragment>
            )}
              <Box title="Font Metadata" style={{ width: '10%' }} align="end" justify="end" alignContent="end">
                <Button icon={<ObjectGroup />} onClick={() => collectGlyphClick(project)} />
              </Box>
              <Box title="View SVG Files" style={{ width: '10%' }} align="end" justify="end" alignContent="end">
                <Button icon={<View />} onClick={() => viewSvgFilesClick(project.id)} />
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <Text>No projects available</Text>
      )}
    </>
  );

  return (
    <>
      <ErrorModal
        headerText={errorHeading}
        isModalOpen={showErrorModal}
        showWarning={true}
        warningText={errorWarningText}
        modalText={errorMessageBody}
        button1Handler={() => setShowErrorModal(false)}
      />
      <ConfirmationModal
        headerText={`Delete Project`}
        isModalOpen={showDeleteProject}
        modalText="Are you sure you want to delete project?"
        onCancel={() => setShowDeleteProject(false)}
        onConfirm={() => deleteProject()}
        children={
          <Box margin={{ left: 'small' }}>
            <Text size="small" weight="bolder">
              {selectedProject?.projectName}
            </Text>
          </Box>
        }
      />
      <Page kind="full">
        <Box direction="row" fill className="page-content-wrapper">
       <Sidebar 
          userId={userId} 
          userRole={userRole} 
           onOpenRegistrationModal={onOpenRegistrationModal}// Pass the function
          />
           <UserRegistrationModal
            isModalOpen={isRegistrationModalOpen}
            onModalClose={onRegistrationModalClose}
          />
          <PageContent height="100%" className="main-content">
            <FontPageHeader
              title="My Font Projects"
              subTitle="Manage your font projects here"
              showActionButton
              actionButtonText="Create project"
              actionButtonHandler={() => setShowCreateProjectModal(true)}
            />         
            <Box height="100%">
              {apiEndpointCallStates.getAllProjectsInFlight ? (
                <PageSpinner showSpinner={true} />
              ) : (
                projectsList && projectsList.length ? (
                  <div style={{ width: '100%', height: '100%' }}>
                    {listHeader()}
                    <Box gap="xsmall">{projectsListComponent()}</Box>
                  </div>
                ) : (
                  <Box margin="small" align="center" height="100%" direction="row">
                    <Box alignContent="center" align="center" width="100%">
                      <Text color="text-weak" size="xxlarge">
                        Create a project to get started
                      </Text>
                    </Box>
                  </Box>
                )
              )}
            </Box>
          </PageContent>
        </Box>
      </Page>

      {showLogPanel && selectedProject && !isNaN(userId) && (
        <div className="overlay">
          <FileUploadComponent
            userId={userId}
            projectId={selectedProject.id}
            closeModal={() => setShowLogPanel(false)}
          />
        </div>
      )}
      {showGlyphPanel && selectedProject && !isNaN(userId) && (
        <div className="overlay">
          <UploadGlyphPage
            userId={userId}
            projectId={selectedProject.id}
            closeModal={() => setShowGlyphPanel(false)}
          />
        </div>
      )}
      {showSvgFiles && (
        <div className="overlay">
          <SvgFilesPage
            svgFiles={svgFiles}
            closeModal={() => setShowSvgFiles(false)}
          />
        </div>
      )}

      <CreateProjectModal
        isModalOpen={showCreateProjectModal}
        onClose={() => setShowCreateProjectModal(false)}
        onCreate={handleCreateProject} // Pass the handleCreateProject function
        userId={userId} // Pass userId to CreateProjectModal
      />
    </>
  );
};

export const ProjectsListPage = observer(ProjectsListPageImpl);
function setRegistrationModalOpen(arg0: boolean) {
  throw new Error('Function not implemented.');
}

