/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import React, { useState, useEffect } from 'react';
import { ModalDefault } from '../sharedComponents/ModalDefault';
import './UserRegistrationModal.scss';
import { AuthenticateUserRequest } from '../../models';
import { useStores } from '../../stores/use-stores';

interface UserRegistrationModalProps {
  isModalOpen: boolean;
  onModalClose: () => void;
}

const UserRegistrationModal: React.FC<UserRegistrationModalProps> = ({ isModalOpen, onModalClose }) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [role, setRole] = useState<string>('User'); // Default role
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [successMessage, setSuccessMessage] = useState<string>('');
  const { apiStore } = useStores();

  const clearForm = () => {
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setRole('User'); // Reset to default role
    setErrorMessage('');
    setSuccessMessage('');
  };

  useEffect(() => {
    if (isModalOpen) {
      clearForm();
    }
  }, [isModalOpen]);

  const onUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setUsername(event.target.value);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const onConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassword(event.target.value);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const onRoleChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    setRole(event.target.value);
    setErrorMessage('');
    setSuccessMessage('');
  };

  const onSignUpButtonClick = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    try {
      const user: AuthenticateUserRequest = {
        username,
        password,
        role, // Include role in the user object
      };
      await apiStore.createUser(user);
      setSuccessMessage("User created successfully.");
      setTimeout(() => {
        setSuccessMessage('');
        onModalClose();
      }, 2000);
    } catch (error) {
      console.error('Failed to create user. Please try again:', error);
        let errorMessage = 'Failed to create user. Please try again';
  
        if (error instanceof Error) {
          errorMessage += `: ${error.message}`;
        } else {
          errorMessage += `: ${JSON.stringify(error)}`;
        }
  
        setErrorMessage(errorMessage);
    }
  };

  return (
    <ModalDefault
      isModalOpen={isModalOpen}
      headerText="Create Account"
      showCloseButton={false} // Hide the default close button
      onModalCloseButtonClick={() => {
        clearForm();
        onModalClose();
      }}
    >
      <div className="user-registration-form">
        {/* Close Button */}
        <button className="close-button" onClick={() => {
          clearForm();
          onModalClose();
        }}>
          &times;
        </button>

        <div className="form-control">
          <label htmlFor="username">User Name</label>
          <input
            type="text"
            id="username"
            name="username"
            placeholder="UserName"
            value={username}
            onChange={onUsernameChange}
          />
        </div>
        <div className="form-control">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            placeholder="Enter your password"
            value={password}
            onChange={onPasswordChange}
          />
        </div>
        <div className="form-control">
          <label htmlFor="confirm-password">Re-type Password</label>
          <input
            type="password"
            id="confirm-password"
            name="confirm-password"
            placeholder="Re-enter your password"
            value={confirmPassword}
            onChange={onConfirmPasswordChange}
          />
        </div>
        <div className="form-control">
          <label htmlFor="role">Role</label>
          <select
            id="role"
            name="role"
            value={role}
            onChange={onRoleChange}
          >
            <option value="User">User</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        <button className="primary-btn" style={{ marginTop: '20px' }} onClick={onSignUpButtonClick}>
          Sign Up
        </button>
        <button className="primary-btn" style={{ marginTop: '20px', marginLeft: '10px' }} onClick={clearForm}>
          Clear
        </button>
      </div>
    </ModalDefault>
  );
};

export default UserRegistrationModal;
