/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { useLocalObservable } from 'mobx-react-lite';
import React from 'react';
import { AppStoresContext } from './app-stores-context';
import { createAppStores } from './create-app-stores';

type Props = {
  children?: React.ReactNode;
};

export const AppStoresProvider: React.FC<Props> = ({ children }) => {
  const stores = useLocalObservable(() => createAppStores());

  return <AppStoresContext.Provider value={stores}>{children}</AppStoresContext.Provider>;
};
