/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import React from 'react';

import { AppStores } from './create-app-stores';

export const AppStoresContext = React.createContext<AppStores | undefined>(undefined);
