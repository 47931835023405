/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import React from 'react';
import { Tip } from 'grommet';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { useStores } from '../../stores/use-stores';
import './Header.scss';
import { Avatar } from 'grommet';
import logo from '../images/rit-logo.png';
import { User } from 'grommet-icons';

const HeaderImpl: React.FC = () => {
  const { authStore } = useStores();
  const navigate = useNavigate();

  const onUserClick = () => {
    authStore.removeAuthInfo();
    navigate('/login');
  };

  return (
    <header className="App-header">
      <div className="App-header-inner">
        <div className="App-logo" onClick={() => navigate('/login')} style={{ cursor: 'pointer' }}>
          <img src={logo} alt="RIT Font Builder Logo" style={{ height: '40px' }} />
        </div>
        <h2 onClick={() => navigate('/login')}>RIT Font Builder</h2>
        {authStore.isAuthenticated && (
          <Tip content="Sign out">
            <button className="sign-out-button" onClick={onUserClick}>
              <Avatar background="brand">
                <User color="white" />
              </Avatar>
            </button>
          </Tip>
        )}
      </div>
    </header>
  );
};

export const Header = observer(HeaderImpl);
