/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { observer } from "mobx-react-lite";
import './Footer.scss';

const FooterImpl: React.FC = () => {
  return (
    <footer>
      <div className="footer-inner">
        <h3>
        {`© 2024–${new Date().getFullYear()} Rachana Institute of Typography`}
        </h3>
        <ul className="footer-nav">
          <li>
            <a href="https://openfontlicense.org/" rel='noreferrer noopener' target='_blank' >License</a>
          </li>
          <li>
            <a href="https://gitlab.com/rit-fonts/" rel='noreferrer noopener' target='_blank' >GitLab</a>
          </li>
          <li>
            <a href="https://rachana.org.in" rel='noreferrer noopener' target='_blank' >Home</a>
          </li>
        </ul>
      </div>
    </footer>
  );
}
export const Footer = observer(FooterImpl);
