/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Project } from '../models';
import { DataCollection } from '../models/data-collection';
import { IFetchContext } from './fetch-context'
import { responseErrorHandler } from './response-error-handler'

export const getAllProjects: (
  fetchContext: IFetchContext,
  userId: Number, accessToken: String ) => Promise<DataCollection<Project>
> = (fetchContext, userId, accessToken) => fetch(`${fetchContext.baseUrl}/${userId}/get-projects`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
  })
  .then((response) => responseErrorHandler<DataCollection<Project>
  >(fetchContext, response, 'post authenticateUser', true, [], true));