/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Project } from '../models';
import { FontMetadata } from '../pages/Projects/FontMetaData';
import { IFetchContext } from './fetch-context'
import { responseErrorHandler } from './response-error-handler'

export const uploadFontMetadata: (
  fetchContext: IFetchContext,
  userId: number,
  projectId: number,
  body: FontMetadata, // The body should be of type FormData
  accessToken: String
) => Promise<void> = (fetchContext, userId, projectId, body, accessToken) => fetch(`${fetchContext.baseUrl}/${userId}/upload_font_metadata/${projectId}`,
  {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`
    },
    body: JSON.stringify(body), // Send data as JSON
  })
  .then((response) => responseErrorHandler<void>(fetchContext, response, 'post upload meta data', true, [], true));
