/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */


import { AuthenticateUserResponse, AuthenticateUserRequest } from '../models';
import { IFetchContext } from './fetch-context'
import { responseErrorHandler } from './response-error-handler'

export const authenticateUser: (
  fetchContext: IFetchContext,
  body: AuthenticateUserRequest
) => Promise<AuthenticateUserResponse> = (fetchContext, body) => fetch(`${fetchContext.baseUrl}/get-login-user-name/${body.username}?password=${body.password}`,
  {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  .then((response) => responseErrorHandler<AuthenticateUserResponse>(fetchContext, response, 'post authenticateUser', true, [], true));