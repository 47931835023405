/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { IConfig } from './config';

export const getConfig: () => IConfig = () => ({
  environment: process.env.REACT_APP_ENV ?? 'dev',
  api: {
    baseUrl: process.env.API_URL ?? '',
  },
});

export const isTestEnvironment = getConfig().environment === 'test';
export const isProdEnvironment = getConfig().environment === 'prod';
