// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

.sidebar {
  width: 200px;
  height: calc(100vh - 60px); /* Adjust height to account for header height */
  background-color: #f0f0f0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  padding-top: 20px; /* Adjust padding-top if needed */
  position: fixed;
  top: 95px; /* Adjust this value to match the height of your header */
  left: 0;
  overflow-y: auto;
  z-index: 1000;
}

.sidebar a {
  display: block;
  color: #00739d; /* Consistent color for both inactive and active links */
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: background-color 0.3s, color 0.3s;
}

.sidebar a:hover {
  background-color: #ddd;
  color: #0056b3; /* Color change on hover */
}

.sidebar a.active {
  background-color: #00739d;
  color: white; /* Color for the active link */
}
`, "",{"version":3,"sources":["webpack://./src/pages/sharedComponents/Sidebar.css"],"names":[],"mappings":"AAAA;;;;;2DAK2D;;AAE3D;EACE,YAAY;EACZ,0BAA0B,EAAE,+CAA+C;EAC3E,yBAAyB;EACzB,wCAAwC;EACxC,aAAa;EACb,iBAAiB,EAAE,iCAAiC;EACpD,eAAe;EACf,SAAS,EAAE,yDAAyD;EACpE,OAAO;EACP,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,cAAc;EACd,cAAc,EAAE,wDAAwD;EACxE,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;EAClB,mBAAmB;EACnB,6CAA6C;AAC/C;;AAEA;EACE,sBAAsB;EACtB,cAAc,EAAE,0BAA0B;AAC5C;;AAEA;EACE,yBAAyB;EACzB,YAAY,EAAE,8BAA8B;AAC9C","sourcesContent":["/* ********************************************************\n# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,\n# Author: Divya C <cdivyanambiar@gmail.com>\n# Author: Rajeesh KV <rajeesh@rachana.org.in>\n# This file is licensed under AGPLv3\n********************************************************* */\n\n.sidebar {\n  width: 200px;\n  height: calc(100vh - 60px); /* Adjust height to account for header height */\n  background-color: #f0f0f0;\n  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);\n  padding: 20px;\n  padding-top: 20px; /* Adjust padding-top if needed */\n  position: fixed;\n  top: 95px; /* Adjust this value to match the height of your header */\n  left: 0;\n  overflow-y: auto;\n  z-index: 1000;\n}\n\n.sidebar a {\n  display: block;\n  color: #00739d; /* Consistent color for both inactive and active links */\n  padding: 10px 15px;\n  text-decoration: none;\n  border-radius: 4px;\n  margin-bottom: 10px;\n  transition: background-color 0.3s, color 0.3s;\n}\n\n.sidebar a:hover {\n  background-color: #ddd;\n  color: #0056b3; /* Color change on hover */\n}\n\n.sidebar a.active {\n  background-color: #00739d;\n  color: white; /* Color for the active link */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
