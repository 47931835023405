import { observer } from 'mobx-react-lite';
import { Route, Routes } from 'react-router-dom';
import { UserLoginPage } from './pages/UserLogin';
import { ProjectsListPage } from './pages/Projects/ProjectsListPage';
import FileUploadComponent from './pages/Projects/FileUploadComponent';
import SvgFilesPage from './pages/Projects/SvgFilesPage';
import UploadFontPage from './pages/Projects/UploadFontMetadata';

function Views() {
  return (
    <Routes>
      <Route path="/login" element={<UserLoginPage />} />
      <Route path="/projects/:userId/:userRole" element={<ProjectsListPage />} />
      <Route
        path="/projects/:userId/upload_files/:projectId"
        element={
          <FileUploadComponent
            userId={0} // Provide default or mock userId if necessary
            projectId={0} // Provide default or mock projectId if necessary
            closeModal={() => { /* Implement closeModal functionality here */ }}
          />
        }
      />
        <Route
        path="/:userId/view_files/:projectId"
        element={
          <SvgFilesPage svgFiles={{}} closeModal={function (): void {
            throw new Error('Function not implemented.');
          } }          
          />
        }
        
      />
     {/* <Route path="/upload-font/:userId" element={<UploadFontPage />} /> */}
    </Routes>
  );
}

export default observer(Views);
