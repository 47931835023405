// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

.subheader {
  font-size: 1.2rem;
  color: #555;
  margin-bottom: 1.5rem;
}

.entry-form {
  margin-top: 20px;
  min-width: 500px;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 15px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 1.5rem;
  color: #333;
  background-color: transparent;
  border: none;
}

.close-button:hover {
  color: #d9534f;
  cursor: pointer;
}

.submit-button {
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  margin-top: 15px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.form-group {
  margin-bottom: 15px;
}

.form-label {
  font-weight: bold;
}

input[type="text"],
input[type="number"],
select {
  width: 100%;
  padding: 5px;
}

input[disabled] {
  background-color: #f1f1f1;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.row {
  display: flex;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Projects/UploadFontMetadata.css"],"names":[],"mappings":"AAAA;;;;;2DAK2D;;AAE3D;EACE,iBAAiB;EACjB,WAAW;EACX,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,6BAA6B;EAC7B,YAAY;AACd;;AAEA;EACE,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;;;EAGE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB","sourcesContent":["/* ********************************************************\n# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,\n# Author: Divya C <cdivyanambiar@gmail.com>\n# Author: Rajeesh KV <rajeesh@rachana.org.in>\n# This file is licensed under AGPLv3\n********************************************************* */\n\n.subheader {\n  font-size: 1.2rem;\n  color: #555;\n  margin-bottom: 1.5rem;\n}\n\n.entry-form {\n  margin-top: 20px;\n  min-width: 500px;\n}\n\nh2 {\n  font-size: 1.5rem;\n  margin-bottom: 15px;\n}\n\n.close-button {\n  position: absolute;\n  top: 10px;\n  right: 10px;\n  font-size: 1.5rem;\n  color: #333;\n  background-color: transparent;\n  border: none;\n}\n\n.close-button:hover {\n  color: #d9534f;\n  cursor: pointer;\n}\n\n.submit-button {\n  background-color: #007bff;\n  color: white;\n  padding: 10px 20px;\n  margin-top: 15px;\n}\n\n.submit-button:hover {\n  background-color: #0056b3;\n}\n\n.form-group {\n  margin-bottom: 15px;\n}\n\n.form-label {\n  font-weight: bold;\n}\n\ninput[type=\"text\"],\ninput[type=\"number\"],\nselect {\n  width: 100%;\n  padding: 5px;\n}\n\ninput[disabled] {\n  background-color: #f1f1f1;\n}\n\n.mb-3 {\n  margin-bottom: 1rem !important;\n}\n\n.row {\n  display: flex;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
