/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { ApiEndpointCallStatesStore, createApiStore } from '../../api';
import { getConfig } from '../../configurations';
import { AuthStore, ErrorStore } from '../data';

export function createAppStores() {
  const config = getConfig();
  const errorStore = new ErrorStore();
  const apiEndpointCallStates = new ApiEndpointCallStatesStore();
  const authStore = new AuthStore();
 
  const apiStore = createApiStore(
    apiEndpointCallStates
  );
  

  return {
    errorStore,
    apiStore,
    apiEndpointCallStates,
    authStore,
  };
}

export type AppStores = ReturnType<typeof createAppStores>;
