/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */

import { Box, Spinner } from 'grommet';

export const PageSpinner: React.FC<{ showSpinner?: boolean }> = (props) => {
  return (
    <>
      <Box pad="medium" align="center" justify="center" flex={true}>
        <Spinner size="medium" />
      </Box>
    </>
  );
};
