/* ********************************************************
# Copyright 2024 Rachana Institutute of Typography <info@rachana.org.in>,
# Author: Divya C <cdivyanambiar@gmail.com>
# Author: Rajeesh KV <rajeesh@rachana.org.in>
# This file is licensed under AGPLv3
********************************************************* */
import { runInAction } from 'mobx';

export const promiseWithObservableState = <TPromise>(
  promise: TPromise,
  setState: (isLoading: boolean) => void,
): TPromise => {
  runInAction(() => {
    setState(true);
  });

  return (promise as unknown as Promise<any>)
    .finally(() => {
      runInAction(() => {
        setState(false);
      });
    }) as unknown as TPromise;
};
